import { useLocation } from '@reach/router';
import React, { FC, memo } from 'react';
import { Icon } from '../shared';
import { Grid } from 'semantic-ui-react';
import { trim } from 'lodash';
import * as Styles from 'src/styles/checkout-features.module.scss';

const CheckoutFeatures: FC<CheckoutFeaturesProps> = ({ product }) => {
    const location = useLocation();
    const params = new URLSearchParams(decodeURIComponent(location.search));
    const icons = product.custom_data?.banner_message_icons?.split(',');

    const data = [];
    for (let i = 1; i <= 4; i++) {
        data.push({
            content: product.custom_data?.[`banner_message_${i}`] || '',
            icon: !!icons && icons.length > 0 ? icons[i - 1] : '',
        });
    }

    const features = data.map((feature, index) => {
        return (
            <Grid.Column computer={4} tablet={13} key={index}>
                <Grid>
                    <Grid.Column mobile={1} tablet={2} computer={3}>
                        <Icon name={`pv-${trim(feature.icon)}`} size={25} />
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <p className="p3">{feature.content}</p>
                    </Grid.Column>
                </Grid>
            </Grid.Column>
        );
    });

    if (params.get('features')?.toString() !== 'true') {
        return null;
    }

    return (
        <Grid centered className={Styles.wrapper}>
            <h1 className="accent">{product.custom_data?.banner_title}</h1>
            <Grid container centered className={Styles.item}>
                {features}
            </Grid>
        </Grid>
    );
};

export default memo(CheckoutFeatures);
