import React from 'react';
import { Link } from 'gatsby';
import { Button } from 'semantic-ui-react';
import { Icon } from 'src/components/shared';

import * as Styles from 'src/styles/confirmation-gift-block.module.scss';

const MindBodyVirtualStudio = () => {
    return (
        <div className={Styles.freeGift}>
            <p className="p1">
                With your P.volve digital membership, you receive a special gift of complimentary
                access to our Virtual Studio for 30 days. To redeem:{' '}
            </p>

            <ul>
                <li>
                    <div className="small accent bold">1</div>
                    <p className="p3">
                        Visit P.volve’s Virtual Studio page on MindBody by clicking “Get Started”
                        below
                    </p>
                </li>
                <li>
                    <div className="small accent bold">2</div>
                    <p className="p3">
                        The “Virtual Monthly Unlimited” package will automatically be placed in your
                        cart
                    </p>
                </li>
                <li>
                    <div className="small accent bold">3</div>
                    <p className="p3">
                        Select “Check Out” where you’ll be prompted to create an account or sign
                        into your existing P.volve Virtual MindBody account
                    </p>
                </li>
                <li>
                    <div className="small accent bold">4</div>
                    <p className="p3">
                        Once your account is created, you’ll be directed back to your shopping cart
                    </p>
                </li>
                <li>
                    <div className="small accent bold">5</div>
                    <p className="p3">
                        Enter promo code <strong>HOLIDAYPV</strong> and select “Check Out”
                    </p>
                </li>
            </ul>
            <Button
                as={Link}
                to="https://clients.mindbodyonline.com/classic/ws?studioid=660485&stype=41&sTG=24&prodId=101041"
                secondary
            >
                Get Started &nbsp; &nbsp;
                <Icon name="pv-external-link" size={14} color="white" />
            </Button>
            <p className="tiny">
                <a href="https://clients.mindbodyonline.com/classic/ws?studioid=660485&stype=41&sTG=24&prodId=101041">
                    powered by mindbody.com
                </a>
            </p>
        </div>
    );
};

export default MindBodyVirtualStudio;
