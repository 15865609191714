import React, { useState, useContext, useCallback } from 'react';
import Pvolve from '@pvolve/sdk';
import classnames from 'classnames';

import { connect, ConnectedProps, useSelector } from 'react-redux';
import { Item, Form, Table } from 'semantic-ui-react';
import { isEmpty, isNull, find, some } from 'lodash';
import { Formik } from 'formik';
import { IState } from '@pvolve/sdk/src/redux/selectors';

import Actions from 'src/state/root-actions';
import Selectors from 'src/state/root-selectors';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import CheckoutItem from 'src/components/checkout/CheckoutItem';
import { Icon, BenefitsTrial } from 'src/components/shared';
import { OrderContext, ShopifyDataContext } from 'src/components/checkout/checkout-context';
import { getErrorMessage, getInfoMessage } from 'src/utils/form-utils';
import { CheckoutPromoEventPayload } from 'src/state/web/sagas';
import withLocation from '../withLocation';
import CheckoutItemFeatured from './CheckoutItemFeatured';

import * as Styles from 'src/styles/checkout.module.scss';
import CheckoutPlanTypes from './CheckoutPlanTypes';
import { notifyBugsnagError } from 'src/utils/bugsnag';

const FREE_SHIPPING_COPY = 'FREE (continental U.S.)';
const ENTER_ADDRESS_COPY = 'Enter address to calculate';

const mapStateToProps = (state: IState) => ({
    loggedIn: Selectors.auth.loggedIn(state),
    shopifyUrl: PvolveSelectors.config.shopifyUrl(state),
});

const connector = connect(mapStateToProps, {
    checkoutPromo: (data: CheckoutPromoEventPayload) => Actions.web.checkoutPromo(data),
});

interface CheckoutSummaryProps extends ConnectedProps<typeof connector> {
    code?: string;
    isFreeTrial?: boolean;
    version?: string;
    location: any;
}

const CheckoutSummary = ({
    code,
    loggedIn,
    checkoutPromo,
    isFreeTrial,
    version,
    location,
}: CheckoutSummaryProps) => {
    const [promoCodeVisible, setPromoCodeVisible] = useState(true);
    const shopifyUrl = useSelector(Selectors.config.shopifyUrl);

    const {
        cart,
        draftOrderUpdating,
        freeTrialDays,
        updateOrderContext,
        shippingAddress,
        ...restOrderContext
    } = useContext(OrderContext);

    const mock_draft_order = {
        draft_order_id: 13241234321423,
        discount_summary: null,
        price_summary: {
            discount: null,
            shipping: null,
            taxes: null,
            subtotal: 1999,
            total: 1999,
        },
    };

    let draft_order = isEmpty(restOrderContext.draft_order)
        ? mock_draft_order
        : restOrderContext.draft_order;

    const {
        draft_order_id,
        discount_summary,
        price_summary: { discount, shipping, subtotal, taxes, total },
    } = draft_order;

    const { shippingData } = useContext(ShopifyDataContext);
    const domesticData = find(shippingData, { name: 'Domestic' });
    const domesticCountry = domesticData?.countries.find(
        (i) => shippingAddress.indexOf(i.name) > -1
    );

    /*
        TODO: Refactor this to do something better.
    */

    const config = useSelector(Selectors.config.commerce);
    const freeShippingSKUs: string[] = config?.freeShippingSKUs || [];
    const hasFreeShippingProduct = some(cart?.items, (item) => freeShippingSKUs.includes(item.sku));
    const params = new URLSearchParams(decodeURIComponent(location.search || ''));
    const typesExist = params.get('types')?.toString() === 'true';
    const featuresExist = params.get('features')?.toString() === 'true';

    const domesticShipping =
        !shippingAddress.length || shippingAddress.find((i) => i === domesticCountry?.name);
    const freeDomesticShipping = hasFreeShippingProduct || (subtotal >= 9900 && domesticShipping);

    const getPromoCodeDescription = () => {
        if (discount_summary) {
            const { description, discount } = discount_summary;

            if (description) {
                return description;
            } else if (discount?.code) {
                const amount =
                    discount.type === 'percentage'
                        ? `${discount.percentage}%`
                        : `$${(discount.amount / 100).toFixed(2)}`;
                const product = discount.applies_to_cart ? 'purchase' : `some product`;

                return `${amount} off ${product}`;
            }
        }

        return null;
    };
    const promoData = ({ values, errors, touched, isSubmitting }) => {
        const hasErrors = errors.promoCode && touched.promoCode;
        const showSuccess = !hasErrors;
        const buttonDisabled = isEmpty(values.promoCode) || isSubmitting || draftOrderUpdating;
        const buttonLabel = discount_summary?.discount?.code ? 'Remove' : 'Apply';
        const infoMessage = getPromoCodeDescription();
        return { hasErrors, showSuccess, buttonDisabled, buttonLabel, infoMessage };
    };

    const onTitleClick = useCallback(() => {
        setPromoCodeVisible(!promoCodeVisible);
    }, [promoCodeVisible]);

    const formatCurrency = (priceInInteger: number) =>
        priceInInteger === 0 ? '$0' : `$${(priceInInteger / 100).toFixed(2)}`;

    const handlePromoButtonClick = async (values, actions) => {
        updateOrderContext({
            draftOrderUpdating: true,
        });

        // If there's already a discount, we want to remove it
        const code = discount_summary?.discount?.code ? '' : values.promoCode;

        try {
            const { data, message } = await Pvolve.api.commerce.updateV2(
                {
                    draft_order_id,
                    code,
                },
                loggedIn
            );

            if (isEmpty(data)) {
                actions.setFieldError('promoCode', message ? message : 'Invalid discount code');

                updateOrderContext({
                    draftOrderUpdating: false,
                });

                return;
            }

            updateOrderContext({
                draft_order: data,
                draftOrderUpdating: false,
            });

            if (code === '') {
                actions.setFieldValue('promoCode', '');
            }

            // GTM event for promo codes
            checkoutPromo({
                action: code === '' ? 'remove' : 'apply',
                code,
                draft_order,
            });
        } catch (error) {
            notifyBugsnagError(error);

            // TODO: what to do here?
            console.log({ error });
        }
    };

    const items = cart?.items;
    const createCheckoutItems = useCallback(() => {
        if (items) {
            return items
                .sort((a, b) => a.price - b.price)
                .map((item, index) => {
                    let itemDiscount = null;

                    if (discount_summary) {
                        const { discount, item_discounts } = discount_summary;

                        if (!discount?.applies_to_cart && !isEmpty(item_discounts)) {
                            itemDiscount = find(item_discounts, (i) => i.sku === item.sku);
                        }
                    }

                    return featuresExist ? (
                        <CheckoutItemFeatured item={item} key={index} />
                    ) : (
                        <CheckoutItem
                            item={item}
                            key={index}
                            discountAmount={itemDiscount?.product_discount}
                            discount={discount_summary?.discount}
                        />
                    );
                });
        }
    }, [items]);

    if (!draft_order || !cart) {
        return null;
    }

    const requires_shipping = cart?.requires_shipping;
    const discountLabelClasses = classnames(Styles.tableLabel, 'color-pv-black');
    const discountValueClasses = classnames(Styles.tableValue, 'color-pv-black');
    const benefitsList = [
        'Clinically-backed workouts that can help alleviate pain',
        'Free 15-min session with a movement specialist',
        'Tons of content including pelvic floor, meditation, cardio burn workouts',
        'Workouts with 1000s of five-star reviews',
    ];
    const VERSION_1 = 'v1';

    return (
        <>
            {!typesExist &&
                (items ? (
                    <Item.Group unstackable>{createCheckoutItems()}</Item.Group>
                ) : (
                    <p>No items in cart</p>
                ))}
            <CheckoutPlanTypes />
            {!typesExist && !featuresExist && (
                <div className="summary-wrapper">
                    {freeTrialDays === 0 && (
                        <h6>
                            <a className="upper text-links" href={`${shopifyUrl}/cart`}>
                                edit cart
                            </a>
                        </h6>
                    )}

                    {!isFreeTrial && (
                        <Formik
                            initialValues={{ promoCode: code || '' }}
                            onSubmit={handlePromoButtonClick}
                        >
                            {(formikProps) => {
                                const {
                                    values,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    isSubmitting,
                                } = formikProps;
                                const {
                                    hasErrors,
                                    showSuccess,
                                    buttonDisabled,
                                    buttonLabel,
                                    infoMessage,
                                } = promoData(formikProps);
                                const formClasses = classnames(Styles.promoCodeContainer, {
                                    active: promoCodeVisible,
                                });

                                return (
                                    <Form
                                        onSubmit={formikProps.handleSubmit}
                                        noValidate
                                        className={formClasses}
                                    >
                                        <h6 className="upper bold" onClick={onTitleClick}>
                                            Enter Promo Code
                                            <Icon name="pv-chevron-up" size={12} />
                                        </h6>
                                        <Form.Input
                                            id="checkout-promo-code"
                                            name="promoCode"
                                            type="text"
                                            placeholder="promo code"
                                            value={values.promoCode}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                hasErrors
                                                    ? getErrorMessage(errors.promoCode)
                                                    : false
                                            }
                                            action={{
                                                content: buttonLabel,
                                                disabled: buttonDisabled,
                                                loading: isSubmitting,
                                                type: 'submit',
                                            }}
                                        />
                                        {showSuccess &&
                                            infoMessage &&
                                            getInfoMessage(infoMessage, 'SUCCESS')}
                                    </Form>
                                );
                            }}
                        </Formik>
                    )}
                    <Table basic="very" unstackable>
                        <Table.Body>
                            {freeTrialDays && !requires_shipping ? (
                                <Table.Row>
                                    <Table.Cell className={Styles.tableTotal}>
                                        Total due today
                                    </Table.Cell>
                                    <Table.Cell className={Styles.tableTotal} textAlign="right">
                                        {formatCurrency(0)}
                                    </Table.Cell>
                                </Table.Row>
                            ) : (
                                <>
                                    <Table.Row>
                                        <Table.Cell className={Styles.tableLabel}>
                                            Subtotal
                                        </Table.Cell>
                                        <Table.Cell className={Styles.tableValue} textAlign="right">
                                            {formatCurrency(subtotal)}
                                        </Table.Cell>
                                    </Table.Row>
                                    {discount && (
                                        <Table.Row>
                                            <Table.Cell className={discountLabelClasses}>
                                                Discount
                                            </Table.Cell>
                                            <Table.Cell
                                                className={discountValueClasses}
                                                textAlign="right"
                                            >
                                                {draftOrderUpdating
                                                    ? 'calculating...'
                                                    : `-${formatCurrency(discount)}`}
                                            </Table.Cell>
                                        </Table.Row>
                                    )}
                                    {requires_shipping && (
                                        <Table.Row>
                                            <Table.Cell className={Styles.tableLabel}>
                                                Shipping
                                            </Table.Cell>
                                            <Table.Cell
                                                className={Styles.tableValueShipping}
                                                textAlign="right"
                                            >
                                                {draftOrderUpdating
                                                    ? 'calculating...'
                                                    : isNull(shipping)
                                                        ? freeDomesticShipping
                                                            ? FREE_SHIPPING_COPY
                                                            : ENTER_ADDRESS_COPY
                                                        : !shipping && freeDomesticShipping
                                                            ? FREE_SHIPPING_COPY
                                                            : formatCurrency(shipping)}
                                            </Table.Cell>
                                        </Table.Row>
                                    )}
                                    <Table.Row>
                                        <Table.Cell className={Styles.tableLabel}>Taxes</Table.Cell>
                                        <Table.Cell className={Styles.tableValue} textAlign="right">
                                            {draftOrderUpdating
                                                ? 'calculating...'
                                                : isNull(taxes)
                                                    ? ENTER_ADDRESS_COPY
                                                    : formatCurrency(taxes)}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell className={Styles.tableTotal}>Total</Table.Cell>
                                        <Table.Cell className={Styles.tableTotal} textAlign="right">
                                            {draftOrderUpdating ? '--' : formatCurrency(total)}
                                        </Table.Cell>
                                    </Table.Row>
                                </>
                            )}
                        </Table.Body>
                    </Table>
                </div>
            )}
            {isFreeTrial && version?.toLowerCase() === VERSION_1 && (
                <BenefitsTrial title="Your Free Trial Includes:" items={benefitsList} withStyles />
            )}
        </>
    );
};

export default connector(withLocation(CheckoutSummary));
