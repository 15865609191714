import AddressFormatter, { Country, FieldName } from '@shopify/address';
import { findIndex, filter, flatten, includes, isEmpty, pull, remove } from 'lodash';

export const SUPPORTED_COUNTRIES = [
    'US',
    'GB',
    'CA',
    'AU',
    'DE',
    'IT',
    'FR',
    'ES',
    'NL',
    'CH',
    'SG',
    'HK',
];
export const DEFAULT_COUNTRY = 'US';

/** TODO: does @shopify/address export these? */
export const SHOPIFY_FIELD_NAMES = {
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    COMPANY: 'company',
    ADDRESS1: 'address1',
    ADDRESS2: 'address2',
    CITY: 'city',
    COUNTRY: 'country',
    ZONE: 'province',
    POSTAL_CODE: 'zip',
    PHONE: 'phone',
};

export const IGNORED_FIELDS = [`${SHOPIFY_FIELD_NAMES.COMPANY}`, `${SHOPIFY_FIELD_NAMES.PHONE}`];

export const OPTIONAL_FIELDS = [...IGNORED_FIELDS, `${SHOPIFY_FIELD_NAMES.ADDRESS2}`];

interface OrderedFields {
    [key: string]: FieldName[][];
}

interface RequiredFields {
    [key: string]: string[];
}

export default class CountriesDataService {
    private _formatter: any;
    private _countries: Country[];
    private _orderedFields: OrderedFields;
    private _requiredFields: RequiredFields;

    constructor() {
        this._formatter = new AddressFormatter('en-US');
        this._countries = [];
        this._orderedFields = {};
        this._requiredFields = {};
    }

    get formatter() {
        return this._formatter;
    }

    get countries() {
        return this._countries;
    }

    get orderedFields() {
        return this._orderedFields;
    }

    get requiredFields() {
        return this._requiredFields;
    }

    /**
     * Download all country address data from shopify.
     * Cache the data for only supported countries.
     *
     * @async
     * @function fetchCountries
     * @return {undefined}
     */
    fetchCountries = async () => {
        // If data has been fetched before, return cached version
        if (!isEmpty(this._countries)) {
            return this._countries;
        }

        // Fetch country address data from shopify
        const countries = await this._formatter.getCountries();

        // Filter for only supported countries
        const filteredCountries = filter(countries, (c) => includes(SUPPORTED_COUNTRIES, c.code));

        this._countries = filteredCountries;
    };

    /**
     * Return address data for a specified country code.
     *
     * @async
     * @function getCountryByCode
     * @param {string} code - The country code for address data
     * @return {(Object|undefined)} The address data or undefined if unsupported country
     */
    getCountryByCode = async (code: string) => {
        // If country data not fetched yet, fetch it from shopify
        if (isEmpty(this._countries)) {
            await this.fetchCountries();
        }

        const countryIndex = findIndex(this._countries, (c) => c.code === code);
        return this._countries[countryIndex];
    };

    /**
     * Return ordered address fields for a specified country code from
     * fetched or cached data.
     *
     * @async
     * @function getOrderedFieldsByCode
     * @param {string} code - The country code for ordered fields data
     * @return {(Array|undefined)} The ordered address fields or undefined if unsupported country
     */
    getOrderedFieldsByCode = async (code: string) => {
        if (isEmpty(this._orderedFields[code])) {
            // Fetch ordered fields data from shopify
            const fields = await this._formatter.getOrderedFields(code);

            // Remove any ignored fields (i.e., 'company')
            fields.forEach((fieldGroup: any) => {
                pull(fieldGroup, ...IGNORED_FIELDS);
            });

            remove(fields, (f) => isEmpty(f));

            // Cache response
            this._orderedFields[code] = fields;
        }

        return this._orderedFields[code];
    };

    /**
     * Get formatted display address
     *
     * @async
     * @function formatAddress
     * @param {Object} address - Address fields
     * @return {string} Display format of an address
     */
    formatAddress = async (address: any) => {
        return await this._formatter.format(address);
    };

    /**
     * Return required address fields for a specified country code
     *
     * @async
     * @function getRequiredFieldsByCode
     * @param {string} code - The country code for required fields data
     * @return {(Array|undefined)} The ordered address fields or undefined if unsupported country
     */
    getRequiredFieldsByCode = async (code: string) => {
        // Compute required fields if not cached
        if (isEmpty(this._requiredFields[code])) {
            // get ordered fields
            const fields = await this.getOrderedFieldsByCode(code);

            // flatten into a single level array
            const flattenedFields = flatten(fields);

            // remove optional fields (i.e., 'address2')
            this._requiredFields[code] = filter(
                flattenedFields,
                (f) => !includes(OPTIONAL_FIELDS, f)
            );
        }

        // Return cached data
        return this._requiredFields[code];
    };

    /**
     * Determine if supplied form fields constitute a valid/complete address
     *
     * @async
     * @function getIsValidAddress
     * @param {Object} values - address form data
     * @return {boolean} Flag representing valid/complete state of address
     */
    getIsValidAddress = async (values: any) => {
        // If we don't have values or are missing the country
        // no need to check any further
        if (!values || isEmpty(values.country)) {
            return false;
        }

        // Get required fields for specified country
        let hasAllRequiredAddressFields = true;

        const requiredFields = await this.getRequiredFieldsByCode(values.country);

        // Test that all required fields are specified
        requiredFields.forEach((field) => {
            const fieldValue = values[field];
            if (!fieldValue || isEmpty(fieldValue)) {
                hasAllRequiredAddressFields = false;
            }
        });

        // TODO: in the future, also want to validate field values specifically for the country.

        return hasAllRequiredAddressFields;
    };
}
