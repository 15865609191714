import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import { IState } from '@pvolve/sdk/src/redux/selectors';
import { kebabCase, template, first, lowerCase } from 'lodash';
import { getProductDetails } from 'src/utils/sdk-utils';
import { notifyBugsnagError } from 'src/utils/bugsnag';

export enum PurchaseTypes {
    FREE_TRIAL = 'freeTrial',
    KIT = 'kit',
    DEFAULT = 'default',
}

interface LegalTemplateVariables {
    trialLength?: string;
    planLengthDescription?: string;
    recurringAmount: string;
    buttonText: string;
    discountedAmount?: string;
    planLength?: string;
    handle: string;
}

interface LegalCopyProps {
    purchaseType: PurchaseTypes;
    subscriptionProduct: any;
    freeTrialDays: number;
}

const getFreeTrialLegalMarkup = ({
    trialLength,
    planLengthDescription,
    recurringAmount,
}: LegalTemplateVariables) =>
    `<p class="tiny legal-copy"><span class="bold">Your subscription automatically renews for ${recurringAmount} per year starting at the end of the ${trialLength} period, until cancelled.</span> To avoid being charged, you must cancel before the end of the active free trial or renewal term. You can cancel through our Subscription page.</p>`;

const getDefaultLegalMarkup = (
    { planLengthDescription, recurringAmount, handle }: LegalTemplateVariables,
    metafieldData: string,
    duration: string
) => {
    let isDigitalPresent = handle === 'streaming-access';
    let isKit = handle.includes('kit');

    const setDescription = isDigitalPresent
        ? lowerCase(metafieldData)
        : isKit
            ? kebabCase(duration)
            : planLengthDescription;

    return `<p class="p2 accent bold legal-copy">You are signing up for a ${setDescription} membership for ${recurringAmount}.</p><p class="tiny legal-copy">Your membership will be continuous and will automatically renew unless you cancel before the end of the membership period by going to the Membership page and selecting "Cancel Membership."</p>`;
};

const createLegalMarkup = (
    copyType: PurchaseTypes,
    templateVariables: LegalTemplateVariables,
    shopifyUrl: string | undefined,
    metafieldData: string | undefined,
    duration: string | undefined
) => {
    let markup = '';

    switch (copyType) {
        case PurchaseTypes.FREE_TRIAL:
            markup = getFreeTrialLegalMarkup(templateVariables);
            break;
        case PurchaseTypes.KIT:
        case PurchaseTypes.DEFAULT:
        default:
            markup = getDefaultLegalMarkup(templateVariables, metafieldData, duration);
            break;
    }

    return {
        __html: `<div class="margin-top--large">${markup}<p class="tiny">By selecting "${templateVariables.buttonText}" you agree to the above and to the Pvolve <a class="bold" href="${shopifyUrl}/pages/terms-of-service">terms of service</a>.</p></div>`,
    };
};

const getPlanLengthDescription = (variant_title: string) => {
    let desc = kebabCase(variant_title);
    desc = desc.replace(/s$/, '');

    if (desc === '1-month') {
        return 'monthly';
    }

    if (desc === '12-month') {
        return 'yearly';
    }

    return desc;
};

const connector = connect((state: IState) => ({
    shopifyUrl: PvolveSelectors.config.shopifyUrl(state),
}));

const LegalCopy = ({
    purchaseType,
    subscriptionProduct,
    freeTrialDays,
    shopifyUrl,
}: LegalCopyProps & ConnectedProps<typeof connector>) => {
    const [metafieldData, setmetafieldData] = useState<string>('');
    const [duration, setDuration] = useState<string>('');
    const { variant_title, price, sku, handle } = subscriptionProduct;

    let templateVariables = {
        planLengthDescription: getPlanLengthDescription(variant_title),
        recurringAmount: `$${price / 100}`,
        buttonText: 'COMPLETE ORDER',
        trialLength: '',
        handle: handle,
    };

    if (purchaseType === PurchaseTypes.FREE_TRIAL) {
        templateVariables.trialLength = `${freeTrialDays}-day`;
        templateVariables.buttonText = 'START FREE TRIAL';
    }

    useEffect(() => {
        const load = async () => {
            try {
                if (!!sku) {
                    const response = await getProductDetails(sku);
                    const product = first(response);

                    if (product) {
                        setmetafieldData(product.custom_data?.alt_title);
                        setDuration(product.custom_data?.duration);
                    }
                }
            } catch (error) {
                notifyBugsnagError(error);

                console.log({ error });
                return {};
            }
        };

        load();
    }, [sku]);

    return (
        <div
            dangerouslySetInnerHTML={createLegalMarkup(
                purchaseType,
                templateVariables,
                shopifyUrl,
                metafieldData,
                duration
            )}
        />
    );
};

export default connector(LegalCopy);
