import React, { FC, memo, useState } from 'react';
import 'src/styles/checkout-header-modal.scss';
import { GrClose } from 'react-icons/gr';
import { FaRegClipboard } from 'react-icons/fa';
import { MdPersonOutline } from 'react-icons/md';
import { FiPlayCircle } from 'react-icons/fi';
import { AiOutlineStar } from 'react-icons/ai';

const trialFeatures = [
    {
        icon: <FaRegClipboard />,
        label: 'Clinically-backed workouts that can help alleviate pain',
    },
    {
        icon: <MdPersonOutline />,
        label: 'Free 15-min session with a movement specialist',
    },
    {
        icon: <FiPlayCircle />,
        label: 'Tons of content including pelvic floor, meditation, cardio burn workouts',
    },
    {
        icon: <AiOutlineStar />,
        label: 'Workouts with 1000s of five-star reviews',
    },
];

const CheckoutHeaderModal: FC = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const params = new URLSearchParams(decodeURIComponent(window?.location?.search || ''));

    const Features = ({ ...feature }) => (
        <div className="checkout-header__modal-item">
            {feature.icon}
            <span className="checkout-header__label">{feature.label}</span>
        </div>
    );

    if (params.get('types') !== 'true') {
        return null;
    }

    return (
        <div className="checkout-header">
            <h1 className="checkout-header__title">You are going to love the way you feel</h1>
            <a className="checkout-header__link" onClick={() => setModalOpen(true)}>
                What's included in the trial?
            </a>
            {modalOpen && (
                <>
                    <div className="checkout-header__modal">
                        <button
                            className="checkout-header__btn"
                            onClick={() => setModalOpen(false)}
                        >
                            <GrClose />
                        </button>
                        <div className="checkout-header__modal-body">
                            <div className="checkout-header__list">
                                <h2 className="checkout-header__modal-title">
                                    Your free trial includes:
                                </h2>
                                {trialFeatures.map((feature, i) => (
                                    <Features key={`feature-${i}`} {...feature} />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div
                        className="checkout-header__backdrop"
                        onClick={() => setModalOpen(false)}
                    />
                </>
            )}
        </div>
    );
};

export default memo(CheckoutHeaderModal);
