import React, { useState, useEffect, useContext } from 'react';
import Pvolve from '@pvolve/sdk';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import Selectors from 'src/state/root-selectors';

import { navigate } from 'gatsby';
import { Formik } from 'formik';
import { isEmpty, find, includes, isNull } from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Form, Grid } from 'semantic-ui-react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { IState } from '@pvolve/sdk/src/redux/selectors';
import { CreditCard } from '@pvolve/sdk/src/CommerceService';

import Actions from 'src/state/root-actions';
import LegalCopy, { PurchaseTypes } from 'src/components/checkout/LegalCopy';
import { Banner, ButtonLink, CircleCheckmark, Icon } from 'src/components/shared';
import { OrderContext } from 'src/components/checkout/checkout-context';
import { GENERAL_ERROR_MSG, FORM_MESSAGES, getInfoMessage } from 'src/utils/form-utils';
import { DEFAULT_COUNTRY } from 'src/utils/CountriesDataService';

import * as Styles from 'src/styles/checkout.module.scss';
import classNames from 'classnames';
import { IdentifyFields } from 'src/state/analytics/segment/types';
import Colors from 'src/utils/colors';
import { notifyBugsnagError } from 'src/utils/bugsnag';

const connector = connect(
    (state: IState) => ({
        shopifyUrl: PvolveSelectors.config.shopifyUrl(state),
        userAttrs: Selectors.account.userAttributes(state),
    }),
    {
        // TODO type this data
        checkoutComplete: (data: {
            cart: any;
            order: any;
            freeTrialDays: number;
            customer: { email: string };
        }) => Actions.web.checkoutComplete(data),
        trackPaymentInfoEntered: (isFreeTrial: boolean) => {
            return isFreeTrial
                ? Actions.segment.track.trialPaymentInfoEntered()
                : Actions.segment.track.paymentInfoEntered();
        },
        trackCheckoutOrderCompleted: (data: object) =>
            Actions.segment.track.trialOrderCompleted(data),
        trackCheckoutStepCompleted: (isFreeTrial: boolean) => {
            return isFreeTrial
                ? Actions.segment.track.trialSignupStepCompleted()
                : Actions.segment.track.checkoutStepCompleted();
        },
        identifyUser: (data: IdentifyFields) => Actions.segment.identify(data),
    }
);

interface CheckoutPaymentFormProps extends ConnectedProps<typeof connector> {
    stripeDefaultCard: CreditCard | null;
    stripeClientSecret: string;
    active: boolean;
}

const CheckoutPaymentForm = ({
    checkoutComplete,
    shopifyUrl,
    stripeDefaultCard,
    stripeClientSecret,
    active,
    trackPaymentInfoEntered,
    trackCheckoutOrderCompleted,
    trackCheckoutStepCompleted,
    identifyUser,
    userAttrs,
}: CheckoutPaymentFormProps) => {
    // Purchase State
    const [purchaseType, setPurchaseType] = useState(PurchaseTypes.DEFAULT);
    const [subscriptionProduct, setSubscriptionProduct] = useState(null);

    // Form State
    const [creditCardDirty, setCreditCardDirty] = useState(false); // user has modified the stripe CardElement field
    const [enterNewCard, setEnterNewCard] = useState(true); // user has clicked on 'update payment method' to enter a new card
    const [submitDisabled, setSubmitDisabled] = useState(true); // submit button disabled state

    // Stripe State
    const [stripeReady, setStripeReady] = useState(false); // the stripe JS library and stripe client secret are loaded
    const [stripeError, setStripeError] = useState<string | null>(null); // a stripe api call returned an error
    const [stripeSetupIntent, setStripeSetupIntent] = useState(null); // the stripe setupIntent object returned after calling confrimCardSetup
    const [stripeProcessing, setStripeProcessing] = useState(false); // stripe API call in progress
    const [stripeCardValid, setStripeCardValid] = useState(false); // stripe CardElement field is completed and has no errors
    const [stripeBillingZip, setStripeBillingZip] = useState(null); // zip code from the stripe CardElement field

    const { cart, draft_order, draftOrderUpdating, shippingAddress, freeTrialDays } = useContext(
        OrderContext
    );

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        if (stripeDefaultCard && stripeDefaultCard.payment_id) {
            setStripeCardValid(true);
            setEnterNewCard(false);
        }
    }, [stripeDefaultCard]);

    useEffect(() => {
        // Set Purchase Type based on content of kit.
        if (!cart || !draft_order || isEmpty(cart.items)) {
            return;
        }

        const sub = find(
            cart.items,
            (item) =>
                includes(item.product_type.toLowerCase(), 'streaming') ||
                includes(item.product_type.toLowerCase(), 'single sku kit')
        );
        setSubscriptionProduct(sub);

        if (!!freeTrialDays) {
            setPurchaseType(PurchaseTypes.FREE_TRIAL);
        } else if (sub?.handle.toLowerCase() === 'digital-membership-kit') {
            setPurchaseType(PurchaseTypes.KIT);
        }
    }, [cart, draft_order, freeTrialDays]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const ready = stripe && !!stripeClientSecret;

        if (stripeReady !== ready) {
            setStripeReady(ready);
        }
    }, [stripe, stripeClientSecret]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const stripePaymentReady = stripeReady && stripeCardValid && !stripeProcessing;
        const disabled =
            !stripePaymentReady ||
            draftOrderUpdating ||
            (cart?.requires_shipping && isEmpty(shippingAddress));

        if (submitDisabled !== disabled) {
            setSubmitDisabled(disabled);
        }
    }, [
        stripeReady,
        stripeCardValid,
        stripeProcessing,
        draftOrderUpdating,
        cart?.requires_shipping,
        shippingAddress,
    ]);

    const getSystemErrors = (errors) => {
        const systemErrors = [];

        if (stripeError) {
            systemErrors.push(stripeError);
        }

        if (errors.general) {
            systemErrors.push(errors.general);
        }

        return systemErrors;
    };

    const onFormSubmit = async (values, actions) => {
        let setupIntent;

        const stripeCardElement = elements.getElement(CardElement);

        // Use clientSecret and card details to confirm payment with stripe.
        // Confirm card setup needs to be called either when there is no exisiting setup intent
        // or when the credit card field has been modified (creditCardDirty)
        if (creditCardDirty || !stripeSetupIntent) {
            setStripeProcessing(true);

            // Payment Method passed to stripe is either a default payment id
            // Or, the stripe CardElement
            const payment_method = stripeCardElement
                ? { card: stripeCardElement }
                : stripeDefaultCard?.payment_id || null;

            const confirmCardResponse = await stripe.confirmCardSetup(stripeClientSecret, {
                payment_method,
            });

            setStripeProcessing(false);

            if (!isEmpty(confirmCardResponse?.error)) {
                setStripeError(confirmCardResponse?.error?.message || GENERAL_ERROR_MSG);

                if (!isNull(stripeSetupIntent)) {
                    setStripeSetupIntent(null);
                }

                return;
            }

            setupIntent = confirmCardResponse?.setupIntent;

            if (setupIntent) {
                setStripeSetupIntent(setupIntent);
            }

            setStripeError(null);
            setCreditCardDirty(false);
        }

        // Call Purchase Endpoint - stripe payments, shopify orders, and fullfillment
        let purchasePayload = {
            draft_order_id: draft_order.draft_order_id,
        };

        // If the user entered a credit card, and we have a valid setupIntent
        // then provide the payment_method_id to the BE.
        if (stripeCardElement && setupIntent) {
            purchasePayload.payment_method_id = setupIntent?.payment_method;
        }

        try {
            const { response_code, data: order } = await Pvolve.api.commerce.completeV2(
                purchasePayload
            );

            if (response_code !== 0 || !order) {
                let errMsg = GENERAL_ERROR_MSG;

                if (response_code >= 1000 && response_code <= 1008) {
                    errMsg = 'You card was declined.';
                }

                actions.setFieldError('general', errMsg);

                return;
            }

            // GTM checkout complete event
            checkoutComplete({
                order,
                freeTrialDays,
                cart,
                customer: {
                    email: userAttrs?.object?.email ?? '',
                },
            });
            // START Segment Track event for checkout order complete
            trackCheckoutStepCompleted(!!freeTrialDays);
            if (!!freeTrialDays) {
                if (userAttrs?.object?.email) {
                    identifyUser({
                        userId: userAttrs.object.email,
                    });
                }
                trackCheckoutOrderCompleted(order);
            }
            // END Segment Track event

            navigate(`/checkout/confirm/`, {
                state: {
                    order,
                    requires_shipping: cart?.requires_shipping,
                    cart,
                },
            });
        } catch (error) {
            notifyBugsnagError(error);

            actions.setFieldError('general', GENERAL_ERROR_MSG);
            return;
        }
    };

    const handleCardElementChange = (e) => {
        const postalCode = e.value.postalCode || null;
        const cardValid = e.complete && !e.error;

        if (!creditCardDirty) {
            setCreditCardDirty(true);
        }

        if (postalCode && cardValid && stripeBillingZip !== postalCode) {
            setStripeBillingZip(postalCode);
        }

        if (stripeCardValid !== cardValid) {
            setStripeCardValid(cardValid);

            // START Segment Track event for valid credit card input
            trackPaymentInfoEntered(!!freeTrialDays);
            // END Segment Track event
        }
    };

    const handleUpdateCardClick = (e) => {
        e.preventDefault();
        setEnterNewCard(true);
        setStripeCardValid(false);
    };

    const elementOptions = {
        style: {
            base: {
                color: Colors.gray90,
                fontFamily: "'Muller', 'Helvetica Neue', Arial, Helvetica, sans-serif",
                fontSize: '16px',
                '::placeholder': {
                    color: Colors.gray30,
                },
                textTransform: 'lowercase',
            },
            invalid: {
                color: Colors.lightRed,
                iconColor: Colors.lightRed,
            },
        },
    };

    const initialValues = {
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        country: DEFAULT_COUNTRY,
        city: '',
        province: '',
        zip: '',
        general: '',
    };

    const complete = stripeDefaultCard && !enterNewCard;

    return (
        <li className={classNames({ complete })}>
            {active || complete ? (
                <Formik initialValues={initialValues} onSubmit={onFormSubmit}>
                    {(formikProps) => (
                        <Form id="payment-form" onSubmit={formikProps.handleSubmit}>
                            <h4>
                                {complete && <CircleCheckmark size={40} />}
                                Payment
                            </h4>
                            {complete ? (
                                <>
                                    <p className={Styles.savedCard}>
                                        {`${stripeDefaultCard.brand} ****${stripeDefaultCard.last_four} exp ${stripeDefaultCard.exp_month}/${stripeDefaultCard.exp_year}`}
                                    </p>
                                    <ButtonLink role="button" onClick={handleUpdateCardClick}>
                                        Update Payment
                                    </ButtonLink>
                                </>
                            ) : (
                                <>
                                    {stripeReady && (
                                        <CardElement
                                            className={Styles.stripeCardElement}
                                            options={elementOptions}
                                            onChange={handleCardElementChange}
                                        />
                                    )}
                                </>
                            )}
                            {!isEmpty(getSystemErrors(formikProps.errors)) &&
                                getInfoMessage(FORM_MESSAGES.invalidCard, 'INFO_ERROR')}
                            {active && (
                                <>
                                    {purchaseType && subscriptionProduct && (
                                        <LegalCopy
                                            purchaseType={purchaseType}
                                            subscriptionProduct={subscriptionProduct}
                                            freeTrialDays={freeTrialDays}
                                        />
                                    )}
                                    <Grid columns="equal">
                                        <Grid.Row className={Styles.formActionsWrapper}>
                                            <Grid.Column floated="right" textAlign="right">
                                                <Button
                                                    size="big"
                                                    type="submit"
                                                    loading={
                                                        formikProps.isSubmitting || stripeProcessing
                                                    }
                                                    primary
                                                >
                                                    {!!freeTrialDays
                                                        ? 'Start free trial'
                                                        : 'Complete Order'}
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </>
                            )}
                        </Form>
                    )}
                </Formik>
            ) : (
                <h4 className="only-heading">Payment</h4>
            )}
        </li>
    );
};

export default connector(CheckoutPaymentForm);
