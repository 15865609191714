import React from 'react';
import { Router, useLocation } from '@reach/router';

import CheckoutConfirmationPage from 'src/components/checkout/CheckoutConfirmationPage';
import CheckoutPage from 'src/components/checkout/CheckoutPage';
import NotFoundPage from 'src/pages/404';

const Checkout = () => {
    const { state } = useLocation();

    return (
        <Router basepath="/checkout">
            <CheckoutPage path="/" checkout={true} prevPath={state?.prevPath} />
            <CheckoutConfirmationPage path="/confirm" />
            <NotFoundPage default />
        </Router>
    );
};

export default Checkout;
