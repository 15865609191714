import React, { useState, useEffect, useContext } from 'react';
import classNames from 'classnames';
import Pvolve from '@pvolve/sdk';

import { connect, ConnectedProps } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { filter, find, first, isEmpty, omitBy, remove, sortBy } from 'lodash';
import { Button, Form, Grid, Popup } from 'semantic-ui-react';
import { IState } from '@pvolve/sdk/src/redux/selectors';

import Actions from 'src/state/root-actions';
import Selectors from 'src/state/root-selectors';
import InfoMessage from 'src/components/shared/InfoMessage';

import { Banner, CircleCheckmark, Icon } from 'src/components/shared';
import { CheckoutAddressEventPayload } from 'src/state/web/sagas';
import { DEFAULT_COUNTRY } from 'src/utils/CountriesDataService';
import { getErrorMessage, FORM_MESSAGES, GENERAL_ERROR_MSG } from 'src/utils/form-utils';
import { Step } from 'src/components/checkout/CheckoutPage';
import { OrderContext, ShopifyDataContext } from 'src/components/checkout/checkout-context';
import { SHOPIFY_FIELD_NAMES } from 'src/utils/CountriesDataService';

import * as Styles from 'src/styles/checkout.module.scss';
import { notifyBugsnagError } from 'src/utils/bugsnag';

interface FormValues {
    firstName?: string;
    lastName?: string;
    address1?: string;
    address2?: string;
    country: string;
    city?: string;
    province?: string;
    zip?: string;
    general?: string;
}

type CountryCode = string;

type field = keyof FormValues;

type FormErrors = {
    [key in field]?: string;
};

const CountryPopupContent = () => (
    <div className={Styles.popupWrapper}>
        <Icon name="pv-alert-triangle" />
        <div className={Styles.popupContent}>
            <p>
                If your country is not listed we are unable to process both a membership and a
                physical purchase in the same transaction for your region. Please go{' '}
                <b>back to the cart</b> and remove one of the items.
            </p>
            <p>
                You can still purchase both, however they will need to be in separate transactions.
                Sorry for the inconvenience.
            </p>
        </div>
    </div>
);

const mapStateToProps = (state: IState) => ({
    loggedIn: Selectors.auth.loggedIn(state),
});

const connector = connect(mapStateToProps, {
    checkoutAddressComplete: (data: CheckoutAddressEventPayload) =>
        Actions.web.checkoutAddressComplete(data),
    trackCheckoutStepViewed: () => Actions.segment.track.checkoutStepViewed(),
    trackCheckoutStepCompleted: (data: object = {}) =>
        Actions.segment.track.checkoutStepCompleted(data),
});

interface ShippingAddressFormProps extends ConnectedProps<typeof connector> {
    active: boolean;
    complete: boolean;
    updateCheckoutStep: (step: Step) => void;
    incrementCheckoutStep: () => void;
}

const ShippingAddressForm = ({
    active,
    complete,
    updateCheckoutStep,
    incrementCheckoutStep,
    loggedIn,
    checkoutAddressComplete,
    trackCheckoutStepViewed,
    trackCheckoutStepCompleted,
}: ShippingAddressFormProps) => {
    // Selected data
    const [selectedCountryData, setSelectedCountryData] = useState();
    const [selectedCountryFields, setSelectedCountryFields] = useState([]);

    // Context
    const { draft_order, updateOrderContext, shippingAddress, cart } = useContext(OrderContext);
    const { countriesData, shippingData } = useContext(ShopifyDataContext);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        // On initial mount, we need to render fields for the default country
        getCountryDataAndFields(DEFAULT_COUNTRY);
    }, []);

    const getCountryDataAndFields = async (code: CountryCode) => {
        const countryData = await countriesData.getCountryByCode(code);
        setSelectedCountryData(countryData);

        const countryFields = await countriesData.getOrderedFieldsByCode(code);
        setSelectedCountryFields(countryFields);
    };

    const updateShippingAddressValid = async (values: FormValues) => {
        //  check if shipping address is valid/complete
        return await countriesData.getIsValidAddress(values);
    };

    const getAvailableShippingMethods = async (values: FormValues) => {
        // Get all the shipping zones for the specified country
        const allShippingZones = filter(shippingData, (zone) =>
            find(zone.countries, (country) => values.country === country.code)
        );

        let zone;

        if (allShippingZones.length === 1) {
            // Only one matched shipping zone for the country
            zone = allShippingZones[0];
        } else if (allShippingZones.length > 1) {
            /**
             * More than one matched shipping zone for the country, so,
             * find the shipping zone where the country + province match form values
             *
             * For example, `US` has two shiping zones:
             *  - `Domestic`
             *  - `US Other - Alaska, Hawaii, etc.`
             */
            zone = find(allShippingZones, (z) =>
                find(z.countries, (country) =>
                    find(country.provinces, (p) => values.province === p.code)
                )
            );
        }

        if (zone) {
            let priceBasedShippingRates = [...zone.price_based_shipping_rates];

            // remove any options where purchase subtotal is less than
            // the min subtotal for that shipping rate
            remove(priceBasedShippingRates, (rate) => {
                const maxSubTotal = rate.max_order_subtotal
                    ? parseFloat(rate.max_order_subtotal) * 100
                    : null;
                const minSubTotal = parseFloat(rate.min_order_subtotal) * 100;

                const { subtotal } = draft_order?.price_summary;

                if (maxSubTotal) {
                    return subtotal > maxSubTotal || subtotal < minSubTotal;
                }

                return subtotal < minSubTotal;
            });

            const shippingRatesSortedByPrice = sortBy(priceBasedShippingRates, (rate) =>
                parseFloat(rate.price)
            );

            return shippingRatesSortedByPrice;
        }

        // Edge case - no shipping zone found
        return;
    };

    const onFormSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
        const addressValid = await updateShippingAddressValid(values);

        if (addressValid) {
            const purchaseAddress = omitBy(
                {
                    first_name: values.firstName,
                    last_name: values.lastName,
                    address1: values.address1,
                    address2: values.address2,
                    city: values.city,
                    province: values.province,
                    country: values.country,
                    zip: values.zip,
                },
                isEmpty
            );

            updateOrderContext({
                draftOrderUpdating: true,
            });

            try {
                const shippingRatesSortedByPrice = await getAvailableShippingMethods(values);
                const displayShippingAddress = await countriesData.formatAddress(values);
                const shippingRateId = first(shippingRatesSortedByPrice).id || -1;

                const { data: updatedDraftOrder } = await Pvolve.api.commerce.updateV2(
                    {
                        draft_order_id: draft_order?.draft_order_id,
                        shipping_rate_id: shippingRateId,
                        shipping_address: purchaseAddress,
                        billing_address: purchaseAddress,
                    },
                    loggedIn
                );

                updateOrderContext({
                    draft_order: updatedDraftOrder,
                    shippingAddress: displayShippingAddress,
                    availableShippingRates: shippingRatesSortedByPrice,
                    draftOrderUpdating: false,
                    shippingRateId,
                });

                // Increment to next checkout step
                incrementCheckoutStep();

                // GTM for address step
                checkoutAddressComplete({
                    zip: values.zip || '',
                    country: values.country || '',
                    province: values.province || '',
                    draft_order: updatedDraftOrder,
                });
                // START Segment Track events
                trackCheckoutStepCompleted({ draft_order: updatedDraftOrder });
                trackCheckoutStepViewed();
                // END Segment Track events
            } catch (error) {
                notifyBugsnagError(error);
                // catches errors from both the update call and the getAvailableShippingMethods call
                actions.setFieldError('general', GENERAL_ERROR_MSG);
                updateOrderContext({
                    draftOrderUpdating: false,
                });
            }
        } else {
            actions.setFieldError('general', 'Invalid Address. Please try again.');
        }
    };

    const validate = (values: FormValues) => {
        const errors: FormErrors = {};

        const getRequiredFields = async (code: CountryCode) => {
            return await countriesData.getRequiredFieldsByCode(code);
        };

        getRequiredFields(values.country).then((requiredFields) => {
            requiredFields.forEach((field: field) => {
                if (isEmpty(values[field])) {
                    errors[field] = FORM_MESSAGES.requiredField;
                }
            });
        });

        return errors;
    };

    const handleChangeAddress = () => {
        updateCheckoutStep(Step.address);
    };

    let _values, _errors, _touched, _getFieldLabel, _handleAddressFieldChange, _handleBlur;

    const formField = (fieldName: field, fieldIndex: number) => {
        const fieldLabel = _getFieldLabel(fieldName);
        const fieldKey = `field-${fieldIndex}`;
        const fieldId = `shipping-field-${fieldName}`;
        const fieldValue = _values[fieldName];
        const fieldErrors = _errors[fieldName];
        const fieldTouched = _touched[fieldName];
        const fieldErrMsg = fieldErrors && fieldTouched ? getErrorMessage(fieldErrors) : false;

        if (fieldName === SHOPIFY_FIELD_NAMES.COUNTRY) {
            return (
                <div className={Styles.countryFieldWrapper} key={fieldKey}>
                    <Form.Field
                        label={{
                            children: fieldLabel,
                            htmlFor: fieldId,
                        }}
                        id={fieldId}
                        name={fieldName}
                        control="select"
                        onInput={_handleAddressFieldChange}
                        onBlur={_handleBlur}
                        value={fieldValue}
                        error={fieldErrMsg}
                    >
                        {countriesData.countries.map(listOption('country'))}
                    </Form.Field>
                    {!fieldErrors && (
                        <Popup
                            content={<CountryPopupContent />}
                            on="click"
                            className={Styles.popup}
                            trigger={
                                <InfoMessage
                                    icon="pv-question-mark-circle"
                                    content="Country not listed?"
                                />
                            }
                            basic
                            wide
                        />
                    )}
                </div>
            );
        } else if (fieldName === SHOPIFY_FIELD_NAMES.ZONE) {
            if (isEmpty(selectedCountryData.zones)) {
                return null;
            }

            return (
                <Form.Field
                    key={fieldKey}
                    label={{
                        children: fieldLabel,
                        htmlFor: fieldId,
                    }}
                    id={fieldId}
                    className={`field-${fieldName}`}
                    name={fieldName}
                    control="select"
                    onChange={_handleAddressFieldChange}
                    onBlur={_handleBlur}
                    value={fieldValue}
                    error={fieldErrMsg}
                >
                    <option value="" disabled>
                        --
                    </option>
                    {selectedCountryData.zones.map(listOption('zone'))}
                </Form.Field>
            );
        }

        return (
            <Form.Input
                key={fieldKey}
                id={fieldId}
                name={fieldName}
                className={`field-${fieldName}`}
                label={{
                    children: fieldLabel,
                    htmlFor: fieldId,
                    className: _values[fieldName] ? '' : 'hidden',
                }}
                placeholder={fieldLabel}
                onChange={_handleAddressFieldChange}
                onBlur={_handleBlur}
                value={fieldValue}
                error={fieldErrMsg}
            />
        );
    };

    const listOption = (key) => (c, index) => (
        <option value={c.code} key={`${key}-${index}`}>
            {c.name}
        </option>
    );

    const formGroups = (formGroup: field[], index: number) => (
        <Form.Group widths="equal" key={`form-group-${index}`}>
            {formGroup.map(formField)}
        </Form.Group>
    );

    const displayAddressField = (addressLine: string, index: number) =>
        !isEmpty(addressLine) ? (
            <p key={`address-line-${index}`} className={`address-line-${index}`}>
                {addressLine}
            </p>
        ) : null;

    const initialValues: FormValues = {
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        country: DEFAULT_COUNTRY,
        city: '',
        province: '',
        zip: '',
        general: '',
    };

    return (
        <li className={classNames(Styles.addressForm, { complete })}>
            {active ? (
                <div className={Styles.shippingFormWrapper}>
                    <Formik
                        initialValues={initialValues}
                        validate={validate}
                        onSubmit={onFormSubmit}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            errors,
                            touched,
                            isSubmitting,
                            setFieldValue,
                            dirty,
                        }) => {
                            _getFieldLabel = (fieldName: field) => {
                                // Field Labels are specified in the shopify country data
                                if (fieldName === SHOPIFY_FIELD_NAMES.POSTAL_CODE) {
                                    return selectedCountryData?.labels.postalCode || 'Zip code';
                                } else if (fieldName === SHOPIFY_FIELD_NAMES.ZONE) {
                                    return selectedCountryData?.labels.zone || 'State';
                                }

                                return selectedCountryData?.labels[`${fieldName}`] || '';
                            };

                            _handleAddressFieldChange = async (e) => {
                                // Formik handleChange() method to update values
                                handleChange(e);

                                if (e.target.name === 'country') {
                                    // Anytime the country changes, reset the province value
                                    setFieldValue('province', '');

                                    // Update the selected country data to rerender with new UI form fields
                                    getCountryDataAndFields(e.target.value);
                                }
                            };
                            _handleBlur = handleBlur;
                            _values = values;
                            _errors = errors;
                            _touched = touched;

                            return (
                                <Form id="shipping-address-form" onSubmit={handleSubmit}>
                                    <h4>Shipping Address</h4>
                                    {selectedCountryData && selectedCountryFields.map(formGroups)}
                                    <div
                                        className={`${Styles.formActionsWrapper} padding--0 margin--0`}
                                    >
                                        {!isEmpty(errors.general) && (
                                            <Banner type="ERROR">{errors.general}</Banner>
                                        )}
                                        <div />
                                        <Button
                                            size="big"
                                            type="submit"
                                            className="margin--0"
                                            disabled={isSubmitting || !isEmpty(errors) || !dirty}
                                            loading={isSubmitting}
                                            primary
                                        >
                                            Next
                                        </Button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            ) : !isEmpty(shippingAddress) && complete ? (
                <>
                    <h4>
                        <CircleCheckmark size={40} />
                        Shipping Address
                    </h4>
                    {shippingAddress.map(displayAddressField)}
                    <a className="text-links" onClick={handleChangeAddress}>
                        change address
                    </a>
                </>
            ) : (
                <h4 className="only-heading">Shipping Address</h4>
            )}
        </li>
    );
};

export default connector(ShippingAddressForm);
