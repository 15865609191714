import React, { useCallback, useContext, useRef, useState } from 'react';
import Pvolve from '@pvolve/sdk';
import classNames from 'classnames';

import { connect, ConnectedProps } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { each, find, isEmpty } from 'lodash';
import { Button, Form, Grid } from 'semantic-ui-react';
import { IState } from '@pvolve/sdk/src/redux/selectors';

import Actions from 'src/state/root-actions';
import Selectors from 'src/state/root-selectors';
import ShippingMethodField from 'src/components/checkout/ShippingMethodField';

import { Step } from 'src/components/checkout/CheckoutPage';
import { OrderContext } from 'src/components/checkout/checkout-context';
import { Banner, CircleCheckmark } from 'src/components/shared';
import { GENERAL_ERROR_MSG } from 'src/utils/form-utils';

import * as Styles from 'src/styles/checkout.module.scss';
import { CheckoutShippingEventPayload } from 'src/state/web/sagas';
import { notifyBugsnagError } from 'src/utils/bugsnag';

interface FormValues {
    rate: string | number;
    general?: string;
}

const mapStateToProps = (state: IState) => ({
    loggedIn: Selectors.auth.loggedIn(state),
});

const connector = connect(mapStateToProps, {
    checkoutShippingComplete: (data: CheckoutShippingEventPayload) =>
        Actions.web.checkoutShippingComplete(data),
    trackCheckoutStepViewed: () => Actions.segment.track.checkoutStepViewed(),
    trackCheckoutStepCompleted: (data: object = {}) =>
        Actions.segment.track.checkoutStepCompleted(data),
});

interface ShippingMethodFormProps extends ConnectedProps<typeof connector> {
    active: boolean;
    complete: boolean;
    updateCheckoutStep: (step: Step) => void;
    incrementCheckoutStep: () => void;
}

const ShippingMethodForm = ({
    active,
    complete,
    updateCheckoutStep,
    incrementCheckoutStep,
    loggedIn,
    checkoutShippingComplete,
    trackCheckoutStepViewed,
    trackCheckoutStepCompleted,
}: ShippingMethodFormProps) => {
    // Context
    const {
        draft_order,
        updateOrderContext,
        availableShippingRates,
        shippingRateId,
        cart,
    } = useContext(OrderContext);
    let defaultLoadings = useRef({});

    each(availableShippingRates, ({ id }) => {
        defaultLoadings.current[id] = false;
    });

    const [loadings, setLoadings] = useState(defaultLoadings.current);
    const onFormSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
        updateOrderContext({
            draftOrderUpdating: true,
        });

        // TODO: handle errors here using general field
        try {
            const rateId = parseInt(values.rate);

            const response = await Pvolve.api.commerce.updateV2(
                {
                    draft_order_id: draft_order?.draft_order_id,
                    shipping_rate_id: rateId,
                },
                loggedIn
            );

            const updatedDraftOrder = response?.data;

            updateOrderContext({
                draft_order: updatedDraftOrder,
                shippingRateId: rateId,
                draftOrderUpdating: false,
            });
            const eventDataPayload = {
                draft_order: updatedDraftOrder,
                shipping_method: availableShippingRates.find((rate) => rate.id === rateId)?.name,
            };
            // GTM event for shipping method
            checkoutShippingComplete({
                rateId,
                ...eventDataPayload,
            });
            // START Segment Track events
            trackCheckoutStepCompleted(eventDataPayload);
            trackCheckoutStepViewed();
            // END Segment Track events
        } catch (error) {
            notifyBugsnagError(error);

            actions.setFieldError('general', GENERAL_ERROR_MSG);
            updateOrderContext({
                draftOrderUpdating: false,
            });
        }
        setLoadings(defaultLoadings.current);
    };

    const onlyOneOption = availableShippingRates.length === 1;

    const setLoading = (id: number) => (state: boolean) => {
        setLoadings({
            ...defaultLoadings.current,
            [id]: state,
        });
    };

    const handleChangeMethod = (e) => {
        updateCheckoutStep(Step.shipping);
    };

    const handleNextButton = (onChange) => (e) => {
        onChange(e);
        incrementCheckoutStep();
    };

    const defaultShippingRate: ShippingRate = onlyOneOption
        ? availableShippingRates[0]
        : find(availableShippingRates, { price: '0' });

    const initialValues: FormValues = {
        rate: defaultShippingRate?.id || '',
        general: '',
    };

    const shippingMethodField = useCallback(
        (values, onChange, handleBlur) => (rate, index) => {
            const { name, price, id } = rate;
            const checked =
                values.rate === id ||
                (!isNaN(shippingRateId) && shippingRateId > -1 && id === Number(shippingRateId));

            return (
                <ShippingMethodField
                    key={`shipping-method-${index}`}
                    label={name}
                    price={price}
                    rateId={id}
                    checked={checked}
                    onChange={onChange}
                    onBlur={handleBlur}
                    loading={loadings[id]}
                    setLoading={setLoading(id)}
                />
            );
        },
        [loadings, shippingRateId]
    );

    const shippingMethods = (shippingRateId) => (rate, i) => {
        const price = parseInt(rate.price) === 0 ? 'free' : `\$${rate.price}`;

        if (rate.id === shippingRateId) {
            return (
                <p key={`selected-display-rate-${i}`}>
                    {rate.name} - {price}
                </p>
            );
        }
        return null;
    };

    return (
        <li
            className={classNames(Styles.shippingMethodForm, {
                complete,
            })}
        >
            {active ? (
                <div className={Styles.shippingFormWrapper}>
                    <Formik initialValues={initialValues} onSubmit={onFormSubmit}>
                        {({ handleSubmit, handleChange, handleBlur, values, errors }) => {
                            const onChange = (e: React.FormEvent<HTMLFormElement> | undefined) => {
                                handleChange(e);
                                handleSubmit(e);
                            };

                            return (
                                <Form
                                    id="shipping-method-form"
                                    className={Styles.shippingMethodForm}
                                    noValidate
                                >
                                    <h4>Shipping Method</h4>
                                    {availableShippingRates && (
                                        <Form.Group grouped>
                                            {availableShippingRates.map(
                                                shippingMethodField(values, onChange, handleBlur)
                                            )}
                                            <Button
                                                className={Styles.shippingMethodCta}
                                                onClick={handleNextButton(onChange)}
                                            >
                                                Next
                                            </Button>
                                        </Form.Group>
                                    )}
                                    <Grid>
                                        <Grid.Row className={Styles.formActionsWrapper}>
                                            <Grid.Column floated="right" textAlign="right">
                                                {!isEmpty(errors.general) && (
                                                    <Banner type="ERROR">{errors.general}</Banner>
                                                )}
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            ) : shippingRateId !== -1 && complete ? (
                <>
                    <h4>
                        <CircleCheckmark size={40} />
                        Shipping Method
                    </h4>
                    {availableShippingRates.map(shippingMethods(shippingRateId))}
                    <a className="link" onClick={handleChangeMethod}>
                        change method
                    </a>
                </>
            ) : (
                <h4 className="only-heading">Shipping Method</h4>
            )}
        </li>
    );
};

export default connector(ShippingMethodForm);
