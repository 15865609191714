import React from 'react';
import { noop } from 'lodash';

export interface ShippingRate {
    id: number;
    min_order_subtotal: string;
    name: string;
    price: string;
    shipping_zone_id: number;
}

interface OptionsWithValues {
    name: string;
    value: string;
}

export interface CartItem {
    compare_at_price?: number;
    discounted_price: number;
    discounts: unknown[];
    featured_image: {
        alt: string;
        aspect_ratio: number;
        height: number;
        url: string;
        width: number;
    };
    final_line_price: number;
    final_price: number;
    gift_card: boolean;
    grams: number;
    handle: string;
    id: number;
    image: string;
    key: string;
    line_level_discount_allocations: unknown[];
    line_level_total_discount: number;
    line_price: number;
    options_with_values: OptionsWithValues[];
    original_line_price: number;
    original_price: number;
    price: number;
    product_description: string;
    product_has_only_default_variant: boolean;
    product_id: number;
    product_title: string;
    product_type: string;
    properties: {
        _upsell: boolean;
    };
    quantity: number;
    requires_shipping: boolean;
    sku: string;
    taxable: boolean;
    title: string;
    total_discount: number;
    url: string;
    variant_id: number;
    variant_options: string[];
    variant_title: string;
    vendor: string;
}

export interface Cart {
    attributes: {
        [key: string]: unknown;
    };
    cart_level_discount_applications: unknown[];
    currency: string;
    item_count: number;
    items: CartItem[];
    items_subtotal_price: number;
    note?: string;
    original_total_price: number;
    requires_shipping: boolean;
    token: string;
    total_discount: number;
    total_price: number;
    total_weight: number;
}

export interface DraftOrder {
    draft_order_id?: number;
    price_summary: {
        currency: string;
        discount?: number;
        shipping?: number;
        subtotal: number;
        taxes: number;
        total: number;
    };
}

export interface UpdateOrderContext {
    draftOrderUpdating?: boolean;
    draft_order?: DraftOrder;
    shippingRateId?: number;
}

export interface OrderContextType {
    cart?: Cart;
    draft_order?: DraftOrder;
    draftOrderUpdating: boolean;
    shippingAddress: string[];
    shippingRateId?: number;
    availableShippingRates: ShippingRate[];
    freeTrialDays: number;
    updateOrderContext: (args: UpdateOrderContext) => void;
}

export const defaultOrderContext: OrderContextType = {
    cart: undefined,
    draft_order: undefined,
    draftOrderUpdating: false,
    shippingAddress: [],
    shippingRateId: undefined,
    availableShippingRates: [],
    freeTrialDays: 0,
    updateOrderContext: noop,
};

export const defaultShopifyDataContext = {
    shippingData: null,
    countriesData: null,
};

export const OrderContext = React.createContext(defaultOrderContext);
export const ShopifyDataContext = React.createContext(defaultShopifyDataContext);
