import React, { FC, memo, useEffect, useState } from 'react';
import { Product } from '@pvolve/sdk/src/SubscriptionService';
import { CartItem } from './checkout-context';
import { getProductDetails } from 'src/utils/sdk-utils';
import * as Styles from 'src/styles/checkout-item-featured.module.scss';
import * as PlanTypeStyles from 'src/styles/checkout-plan-types.module.scss';
import cn from 'classnames';
import { notifyBugsnagError } from 'src/utils/bugsnag';

interface CheckoutItemFeaturedProps {
    item: CartItem;
}

const CheckoutItemFeatured: FC<CheckoutItemFeaturedProps> = (props) => {
    const [product, setProduct] = useState<Product>();
    const { item } = props;

    useEffect(() => {
        getProductDetails(item.sku)
            .then(([product]) => setProduct(product))
            .catch((error) => {
                notifyBugsnagError(error);

                console.log(error);
            });
    }, [item.sku]);

    if (!product) {
        return null;
    }

    const getTrialEndDate = (trialDuration: number) => {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + trialDuration);
        return expirationDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
    };

    const productTitle = product.custom_data?.alternative_title || product.title;

    return (
        <div className={Styles.container}>
            <div className={Styles.content}>
                <h6 className="stacked">Risk free</h6>
                <h4 className={`${Styles.title} bold stacked`}>{productTitle}</h4>
                <p className="smallSize">{product.custom_data?.subtitle}</p>
                <p className={`${Styles.price} mini`}>{product.custom_data?.secondary_subtitle}</p>
            </div>
            <div className={PlanTypeStyles.footer}>
                <div className={PlanTypeStyles.group}>
                    <h2 className={PlanTypeStyles.title}>Total due today</h2>
                    <span className={PlanTypeStyles.title}>$0</span>
                </div>
                <span
                    className={cn(
                        PlanTypeStyles.label,
                        PlanTypeStyles.labelSub,
                        PlanTypeStyles.labelGutter
                    )}
                >
                    ${(product.cost.unit_amount / 100).toFixed(2)}{' '}
                    {product.custom_data?.total_due_day_subtitle} {getTrialEndDate(7)}
                </span>
            </div>
        </div>
    );
};

export default memo(CheckoutItemFeatured);
