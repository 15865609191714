/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Pvolve from '@pvolve/sdk';

import { Button } from 'semantic-ui-react';
import { stringify } from 'query-string';
import { WindowLocation } from '@reach/router';

import withLocation from 'src/components/withLocation';
import { Icon } from 'src/components/shared';
import { useBreakpoints, Breakpoints, Directions } from 'src/utils/breakpoint-utils';

import * as socialButtonStyles from 'src/styles/social-button.module.scss';
import { navigate } from 'gatsby';
import { NO_SUPPORT_FACEBOOK, NO_SUPPORT_APPLE_GOOGLE } from 'src/constants/url-constants';

type SocialButtonState = {
    [key: string]: string;
};

interface SocialButtonProps {
    label?: string;
    provider: string;
    redirectPath?: string;
    location: WindowLocation;
    state: SocialButtonState;
    onClick?: (provider: string) => void;
    iconSize?: number;
}

const SocialButton = ({
    label,
    provider,
    location,
    redirectPath,
    state,
    onClick,
    iconSize,
}: SocialButtonProps) => {
    const [oauthLink, setOauthLink] = useState();
    const isTabletPortrait = useBreakpoints(Breakpoints.lg, Directions.up);
    const icon = `pv-${provider}`;
    const text = label || provider.charAt(0).toUpperCase() + provider.slice(1);
    /**`
     * useEffect: callback function argument can not be async.
     *   - async functions return promises.
     *   - useEffect expects an unmount callback func returned
     * useEffect:
     *   - empty [] 2nd argument equates to componentDidMount lifecycle only
     */
    useEffect(() => {
        async function initOauthLink() {
            const url = new URL(location.href);
            const options: { redirect_uri?: string; state?: string } = {};

            if (redirectPath) {
                options.redirect_uri = `${url.origin}${redirectPath}`;
            }

            if (state) {
                options.state = `?${stringify(state)}`;
            }

            const link = await Pvolve.api.cognito.oauthProviderRedirectUrl(
                url.origin,
                provider,
                options
            );

            setOauthLink(link);
        }

        initOauthLink();
    }, []);

    const handleClick = () => {
        if (provider === 'facebook') {
            navigate(NO_SUPPORT_FACEBOOK);
            return;
        }

        if (provider === 'apple' || provider === 'google') {
            navigate(NO_SUPPORT_APPLE_GOOGLE);
            return;
        }

        if (onClick) {
            onClick(provider);
        }

        if (window) {
            window.location.href = oauthLink;
        }
    };

    return (
        <Button
            className={socialButtonStyles.socialButton}
            disabled={!oauthLink}
            labelPosition="left"
            fluid={!isTabletPortrait}
            onClick={handleClick}
            type="button"
            icon
        >
            <Icon name={icon} size={iconSize} />
            {text}
        </Button>
    );
};

export default withLocation(SocialButton);
