import React from 'react';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';

import { Button, Grid } from 'semantic-ui-react';
import { Link } from 'gatsby';
import { connect } from 'react-redux';

import { Banner } from 'src/components/shared';

import * as checkoutStyles from 'src/styles/checkout.module.scss';

const EntitledUser = ({ shopifyUrl, hasGear }) => {
    const copy = hasGear
        ? {
            header: 'Looks like you already have a digital membership.',
            body:
                  'If you’d like to continue purchasing equipment, please update your cart to remove any items that include a digital membership.',
            cta: (
                <Button as="a" size="big" href={`${shopifyUrl}/cart`} fluid>
                      Update My Cart
                </Button>
            ),
        }
        : {
            header: 'Looks like you already have streaming access.',
            body:
                  'You can update your subscription and take advantage of promotions under “Subscription” in your account settings.',
            cta: (
                <Button as={Link} size="big" to="/account/subscription" fluid>
                      Take me to Subscription Settings
                </Button>
            ),
        };

    return (
        <div className={checkoutStyles.checkoutPageWrapper}>
            <Grid className="warning" columns={1} centered>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Banner type="WARNING" withIcon>
                            {copy.header}
                        </Banner>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column textAlign="justified" width={16}>
                        <p>{copy.body}</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column textAlign="center" width={16}>
                        {copy.cta}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column textAlign="center">
                        <a href={shopifyUrl} className="bold upper pv-icon pv-arrow-left">
                            Back to home
                        </a>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
};

const mapStateToProps = (state) => ({
    shopifyUrl: PvolveSelectors.config.shopifyUrl(state),
});

export default connect(mapStateToProps)(EntitledUser);
