import React, { useEffect } from 'react';

// Redux
import { connect, useDispatch } from 'react-redux';
import Actions from 'src/state/root-actions';
import Selectors from 'src/state/root-selectors';

// Components
import { Button } from 'semantic-ui-react';
import MindBodyVirtualStudio from './promos/MindBodyVirtualStudio';
import withLocation from 'src/components/withLocation';
import Layout from 'src/components/layout/Layout';
import { ConfirmationPage, LinkWithArrow } from 'src/components/shared';

// Utils & Constants
import { isEmpty } from 'lodash';
import { ZELDA_HOME_LINK } from 'src/constants/url-constants';
import { linkToExternal } from 'src/utils/url-utils';

const mapStateToProps = (state) => ({
    entitled: Selectors.auth.entitled(state),
    shopifyUrl: Selectors.config.shopifyUrl(state),
    enabledOrderConfirmationPromoConfigs: Selectors.config.enabledOrderConfirmationPromoConfigs(
        state
    ),
    draftId: Selectors.orders.draftId(state),
    user: Selectors.auth.user(state),
});

const connector = connect(mapStateToProps);

const CheckoutConfirmationPage = ({
    location,
    location: { state },
    shopifyUrl,
    enabledOrderConfirmationPromoConfigs,
    draftId,
    user,
}) => {
    const dispatch = useDispatch();

    dispatch(Actions.auth.refresh.trigger());

    useEffect(() => {
        if (!isEmpty(state?.order)) {
            dispatch(Actions.web.setClearShopifyCartCookie.trigger());
            if (state?.order?.draft_order_id === draftId) {
                dispatch(
                    Actions.segment.track.thankyouPageViewed({ order, cart, email: user?.email })
                );
                dispatch(Actions.orders.clearDraftId());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, dispatch]);

    if (!state || isEmpty(state?.order)) {
        if (window) {
            window.location.href = `${shopifyUrl}/cart`;
        }

        return null;
    }

    const { order, requires_shipping, cart } = state;

    const promoComponents = {
        MindBodyVirtualStudio,
    };

    const pageContext = {
        theme: {
            layout: 'plain',
            headerBgColor: 'transparent',
        },
    };

    const refreshAccount = () => {
        dispatch(Actions.auth.watchForEntitlements.trigger());
        dispatch(Actions.account.getTokenAttributes.trigger());
        linkToExternal(ZELDA_HOME_LINK);
    };

    return (
        <Layout pageContext={pageContext} location={location}>
            <ConfirmationPage
                title="THANKS FOR YOUR ORDER"
                subtitle={`YOUR ORDER NUMBER IS #${order.order_number.toString()}`}
            >
                <p className="p1">Why wait? Start now with no-equipment workouts.</p>
                <Button onClick={() => refreshAccount()} secondary>
                    GO ON DEMAND
                </Button>

                {enabledOrderConfirmationPromoConfigs.map((config, index) => {
                    const Component = promoComponents[config.name];

                    if (Component) {
                        return <Component />;
                    }
                    return null;
                })}

                {requires_shipping && (
                    <p className="p1">
                        We're getting your order ready to be shipped. You'll receive an email when
                        it's headed your way.
                    </p>
                )}
                <div>
                    <LinkWithArrow
                        centered
                        label="View Order Details"
                        href={order.order_status_url}
                        target="_blank"
                    />
                </div>
                <div>
                    <LinkWithArrow
                        centered
                        label="Questions? Contact Us"
                        href={`${shopifyUrl}/pages/contact`}
                        target="_blank"
                    />
                </div>
            </ConfirmationPage>
        </Layout>
    );
};

export default connector(withLocation(CheckoutConfirmationPage));
