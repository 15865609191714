import React, { useRef } from 'react';
import fetchJsonp from 'fetch-jsonp';
import Pvolve from '@pvolve/sdk';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';

import { connect, ConnectedProps } from 'react-redux';
import { Grid, Dimmer, Loader } from 'semantic-ui-react';
import { find, map, isEmpty, isInteger, findIndex } from 'lodash';
import { parse } from 'query-string';
import { CreditCard, LineItem } from '@pvolve/sdk/src/CommerceService';
import { IState } from '@pvolve/sdk/src/redux/selectors';

import Actions from 'src/state/root-actions';
import CheckoutAccountForm from 'src/components/checkout/CheckoutAccountForm';
import CheckoutFooter from 'src/components/checkout/CheckoutFooter';
import CheckoutHeaderModal from 'src/components/checkout/CheckoutHeaderModal';
import CheckoutPaymentForm from 'src/components/checkout/CheckoutPaymentForm';
import CheckoutSummary from 'src/components/checkout/CheckoutSummary';
import CountriesDataService from 'src/utils/CountriesDataService';
import EntitledUser from 'src/components/checkout/EntitledUser';
import ErrorPage from 'src/components/shared/error-page/error-page.component';
import Layout from 'src/components/layout/Layout';
import PurchaseError from 'src/components/checkout/PurchaseError';
import Selectors from 'src/state/root-selectors';
import ShippingAddressForm from 'src/components/checkout/ShippingAddressForm';
import ShippingMethodForm from 'src/components/checkout/ShippingMethodForm';
import withLocation from 'src/components/withLocation';

import {
    ShopifyDataContext,
    OrderContext,
    defaultOrderContext,
    defaultShopifyDataContext,
} from 'src/components/checkout/checkout-context';

import * as Styles from 'src/styles/checkout.module.scss';
import * as FormStyles from 'src/styles/form-steps.module.scss';
import { CheckoutPageLoadedEventPayload } from 'src/state/web/sagas';
import CheckoutFeatures from './CheckoutFeatures';
import { notifyBugsnagError } from 'src/utils/bugsnag';

const FREE_TRIAL_SKU_REGEX = /_trial(\d{1,2})$/;
const INCLUDES_GEAR = /single sku kit|equipment/gi;

export enum Step {
    account = 'account/login',
    address = 'shipping address',
    shipping = 'shipping method',
    payment = 'payment',
}

interface CheckoutStep {
    name: Step;
    complete: boolean;
}

export interface ShopifyItem {
    sku: string;
    quantity: number;
    product_title: string;
    image: string;
    variant_title: string;
    price: number;
    final_price: number;
    requires_shipping: boolean;
    product_type: string;
    handle: string;
    product_id?: number;
}

interface Cart {
    token?: string;
    currency?: string;
    items: ShopifyItem[];
    requires_shipping: boolean;
}

interface CheckoutPageState {
    // TODO fill out these anys
    orderContext: {
        cart: Cart | null;
        draft_order: null | any;
        draftOrderUpdating: boolean;
        shippingAddress: string[];
        shippingRateId: number;
        availableShippingRates: null | any;
        freeTrialDays: number;
        updateOrderContext: (newContext: any | null, callback: any | null) => void;
    };
    shopifyDataContext: {
        shippingData: null | any;
        countriesData: null | any;
    };
    stripeClientSecret: null | any;
    stripeDefaultCard: CreditCard | null;
    stripeLoaded: boolean;
    purchaseError: null | string;
    internalServerError: boolean;
    steps: CheckoutStep[];
    currentStepIndex: number;
    isTrial: boolean;
}

const mapStateToProps = (state: IState) => ({
    entitled: Selectors.auth.entitled(state),
    shopifyUrl: PvolveSelectors.config.shopifyUrl(state),
    stripeId: Selectors.auth.stripeId(state),
    loggedIn: Selectors.auth.loggedIn(state),
    userAttributes: Selectors.account.userAttributes(state) || {},
    draftId: Selectors.orders.draftId(state),
    services: PvolveSelectors.config.services(state),
});

const connector = connect(mapStateToProps, {
    checkoutPageLoaded: (data: CheckoutPageLoadedEventPayload) =>
        Actions.web.checkoutPageLoaded(data),
    setDraftId: Actions.orders.setDraftId,
    trackCheckoutStarted: (data: { [x: string]: any }, isFreeTrial: boolean) => {
        return isFreeTrial
            ? Actions.segment.track.trialSignupStarted(data)
            : Actions.segment.track.checkoutStarted(data);
    },
    trackCheckoutStepViewed: (isFreeTrial: boolean) => {
        return isFreeTrial
            ? Actions.segment.track.trialSignupStepViewed()
            : Actions.segment.track.checkoutStepViewed();
    },
});

interface CheckoutPageProps extends ConnectedProps<typeof connector> {
    // TODO fill out these anys
    location: any;
    search: any;
    entitled: boolean;
    shopifyUrl: string;
    stripeId: string;
    loggedIn: boolean;
}

class CheckoutPage extends React.Component<CheckoutPageProps, CheckoutPageState> {
    constructor(props: Readonly<CheckoutPageProps>) {
        super(props);

        this.state = {
            orderContext: {
                ...defaultOrderContext,
                updateOrderContext: this.updateOrderContext.bind(this),
            },
            shopifyDataContext: {
                ...defaultShopifyDataContext,
            },
            stripeClientSecret: null,
            stripeDefaultCard: null,
            stripeLoaded: false,
            purchaseError: null,
            internalServerError: false,
            steps: [],
            currentStepIndex: 0,
            isTrial: false,
            product: null,
        };

        this.getCartAndInitiatePurchase = this.getCartAndInitiatePurchase.bind(this);
        this.getCountriesData = this.getCountriesData.bind(this);
        this.getShippingData = this.getShippingData.bind(this);
        this.stripeInit = this.stripeInit.bind(this);
        this.getPurchaseLineItems = this.getPurchaseLineItems.bind(this);
        this.createCart = this.createCart.bind(this);
        this.initiatePurchase = this.initiatePurchase.bind(this);
        this.updateCheckoutStep = this.updateCheckoutStep.bind(this);
        this.incrementCheckoutStep = this.incrementCheckoutStep.bind(this);
        this.getStepIndexByName = this.getStepIndexByName.bind(this);
        this.shouldDisplayStep = this.shouldDisplayStep.bind(this);
        this.isStepComplete = this.isStepComplete.bind(this);
        this.getUrlParam = this.getUrlParam.bind(this);
        this.getCart = this.getCart.bind(this);
    }

    hasGear = false;

    getPurchaseLineItems(cartData: Cart): LineItem[] {
        return cartData.items
            ? map(cartData.items, ({ sku, quantity }) => ({ sku, quantity }))
            : [];
    }

    async createCart(sku: string): Promise<Cart | undefined> {
        try {
            const [product] = await Pvolve.api.subscription.listSubscriptionProducts(sku);

            if (!product) {
                // We asked the BE for a specific product SKU
                // and we got nothing back
                this.setState({
                    purchaseError: 'Invalid Subscription Product',
                });

                return;
            }

            const isFreeTrial =
                isInteger(product?.subscription_details?.trial_days) &&
                product.subscription_details.trial_days > 0;

            if (isFreeTrial) {
                this.setState({
                    orderContext: {
                        ...this.state.orderContext,
                        freeTrialDays: product.subscription_details.trial_days,
                    },
                    isTrial: isFreeTrial,
                    product: product,
                });
            }

            return {
                items: [
                    {
                        sku,
                        quantity: 1,
                        product_title: product?.title || '',
                        image: product?.image_url || '',
                        variant_title: product?.name || '',
                        price: product?.cost?.unit_amount || 0,
                        final_price: product?.cost?.unit_amount || 0,
                        requires_shipping: false,
                        product_id: product?.shopify_product_id,
                        product_type: 'Streaming',
                        handle: 'streaming-access',
                    },
                ],
                requires_shipping: product?.product_type === 'physical' ? true : false,
            };
        } catch (error) {
            notifyBugsnagError(error);
            // The API call returned a non-200 response
            if (!this.state.internalServerError) {
                this.setState({
                    internalServerError: true,
                });
            }
        }
    }

    async initiatePurchase(cart: Cart, firstLoad = true) {
        try {
            const socialDraftOrderId = this.getUrlParam('draft') || this.props.draftId;
            const promoCode = this.getUrlParam('promo');
            const promoCodePayload = isEmpty(promoCode) ? {} : { code: promoCode };
            let draftOrderResponse;

            if (!socialDraftOrderId) {
                const { data, response_code, message } = await Pvolve.api.commerce.initiateV2(
                    {
                        line_items: this.getPurchaseLineItems(cart),
                        ...promoCodePayload,
                    },
                    this.props.loggedIn
                );

                if (response_code !== 0) {
                    // HTTP status code is 200, but response_code > 0
                    // indicates an application level error with
                    // no data returned and an error message provided
                    if (isEmpty(message)) {
                        if (!this.state.internalServerError) {
                            this.setState({
                                internalServerError: true,
                            });
                        }

                        return;
                    }

                    this.setState({
                        purchaseError: message,
                    });

                    return;
                }

                draftOrderResponse = data;
                const { draft_order_id: id } = draftOrderResponse;

                this.props.setDraftId({ id });
            } else {
                // We have completed a social redirect. Reuse the draft order id in the url `state` query param
                const { data } = await Pvolve.api.commerce.updateV2(
                    {
                        draft_order_id: parseInt(socialDraftOrderId),
                        line_items: this.getPurchaseLineItems(cart),
                        ...promoCodePayload,
                    },
                    this.props.loggedIn
                );

                draftOrderResponse = data; // TODO: Draft order id comes back here. Save to Redux.
            }

            this.setState({
                orderContext: {
                    ...this.state.orderContext,
                    cart,
                    draft_order: draftOrderResponse,
                },
                steps: cart?.requires_shipping
                    ? [
                        { name: Step.account, complete: this.props.loggedIn },
                        { name: Step.address, complete: false },
                        { name: Step.shipping, complete: false },
                        { name: Step.payment, complete: false },
                    ]
                    : [
                        { name: Step.account, complete: this.props.loggedIn },
                        { name: Step.payment, complete: false },
                    ],
            });

            const eventDataPayload = {
                cart,
                draft_order: draftOrderResponse,
            };
            if (firstLoad) {
                // GTM event for checkout page loaded
                this.props.checkoutPageLoaded({
                    loggedIn: this.props.loggedIn,
                    ...eventDataPayload,
                });
                // START SEGMENT EVENT for Checkout Started and Step Viewed
                this.props.trackCheckoutStarted(eventDataPayload, this.state.isTrial);
                this.props.trackCheckoutStepViewed(this.state.isTrial);
                // END SEGMENT EVENT
            }
        } catch (error) {
            notifyBugsnagError(error);

            // The API call returned a non-200 response
            if (!this.state.internalServerError) {
                this.setState({
                    internalServerError: true,
                });
            }
        }
    }

    getUrlParam(name: string) {
        if (this.props.search) {
            if (this.props.search[name]) {
                return this.props.search[name];
            }

            const { state } = this.props.search;

            if (state) {
                const parsedState = parse(state);

                if (parsedState[name]) {
                    return parsedState[name];
                }
            }
        }

        return;
    }

    async getCart(sku?: string): Promise<Cart> {
        sku = sku || this.getUrlParam('sku');
        const cart = await this.createCart(sku!);
        return cart!;
    }

    // Get Cart Data from Shopify
    async getCartAndInitiatePurchase(firstLoad = true) {
        const sku = this.getUrlParam('sku');

        if (sku) {
            const mockShopifyCart = await this.getCart(sku);
            if (mockShopifyCart) {
                await this.initiatePurchase(mockShopifyCart, firstLoad);
            }
        } else {
            const jsonpShopifyUrl = this.props.shopifyUrl || 'https://www.pvolve.com';
            fetchJsonp(`${jsonpShopifyUrl}/cart.json`)
                .then((response) => response.json())
                .then((cartData) => {
                    if (!cartData || isEmpty(cartData.items)) {
                        this.setState({
                            purchaseError: 'Looks like your cart might be empty.',
                        });

                        return;
                    }

                    this.hasGear = find(cartData.items, ({ product_type }) =>
                        product_type.match(INCLUDES_GEAR)
                    );
                    // a free equipment subscription in shopify cart has a sku that ends in "_trialN"
                    // where N is the number of days for the trial
                    const freeTrialIndex = findIndex(cartData.items, (item: ShopifyItem): boolean =>
                        FREE_TRIAL_SKU_REGEX.test(item.sku)
                    );

                    if (freeTrialIndex !== -1) {
                        this.setState({
                            orderContext: {
                                ...this.state.orderContext,
                                freeTrialDays: parseInt(
                                    cartData.items[freeTrialIndex].sku.match(
                                        FREE_TRIAL_SKU_REGEX
                                    )[1]
                                ),
                            },
                        });
                    }

                    this.initiatePurchase(cartData, firstLoad);
                })
                .catch((error) => {
                    notifyBugsnagError(error);

                    // The JSONP fetch failed. We don't have cart data.
                    if (!this.state.internalServerError) {
                        this.setState({
                            internalServerError: true,
                        });
                    }
                });
        }
    }

    // Get Country & Address Data directly from Shopify
    async getCountriesData() {
        try {
            const cData = new CountriesDataService();
            await cData.fetchCountries();

            if (!cData && !this.state.internalServerError) {
                // The API call to shopify for country address data failed
                this.setState({
                    internalServerError: true,
                });

                return;
            }

            this.setState({
                shopifyDataContext: {
                    ...this.state.shopifyDataContext,
                    countriesData: cData,
                },
            });
        } catch (error) {
            notifyBugsnagError(error);

            // The API call returned a non-200 response
            if (!this.state.internalServerError) {
                this.setState({
                    internalServerError: true,
                });
            }
        }
    }

    // Get Shipping Zones Data from SDK API that leverages the Shopify Admin API
    async getShippingData() {
        try {
            const shippingData = await Pvolve.api.commerce.getShippingZones();

            if (!shippingData && !this.state.internalServerError) {
                this.setState({
                    internalServerError: true,
                });

                return;
            }

            this.setState({
                shopifyDataContext: {
                    ...this.state.shopifyDataContext,
                    shippingData,
                },
            });
        } catch (error) {
            notifyBugsnagError(error);
            // The API call returned a non-200 response
            if (!this.state.internalServerError) {
                this.setState({
                    internalServerError: true,
                });
            }
        }
    }

    async stripeInit() {
        // Step 1: Create SetupIntent over Stripe API
        try {
            const { response_code, data: paymentSetup } = await Pvolve.api.commerce.paymentSetup();

            if (response_code !== 0 || !paymentSetup?.client_secret) {
                if (!this.state.internalServerError) {
                    this.setState({
                        internalServerError: true,
                    });
                }

                return;
            }

            this.setState({ stripeClientSecret: paymentSetup.client_secret });
        } catch (error) {
            notifyBugsnagError(error);

            // The API call returned a non-200 response
            if (!this.state.internalServerError) {
                this.setState({
                    internalServerError: true,
                });
            }
        }

        // Step 2: Get default credit card from Stripe, if exists
        try {
            const {
                response_code,
                data: { payment_methods },
            } = await Pvolve.api.commerce.payment();

            if (response_code !== 0) {
                if (!this.state.internalServerError) {
                    this.setState({
                        internalServerError: true,
                    });
                }

                return;
            }

            const defaultCard = find(payment_methods, { is_default: true, type: 'card' });

            if (defaultCard && defaultCard.credit_card) {
                this.setState({ stripeDefaultCard: defaultCard.credit_card });
            }
        } catch (error) {
            notifyBugsnagError(error);
            // The API call returned a non-200 response
            if (!this.state.internalServerError) {
                this.setState({
                    internalServerError: true,
                });
            }
        }

        // Step 3: Indicate that stripe init is complete
        this.setState({ stripeLoaded: true });
    }

    updateOrderContext(newContext: any) {
        this.setState({
            orderContext: {
                ...this.state.orderContext,
                ...newContext,
            },
        });
    }

    updateCheckoutStep(newStep: Step) {
        let newStepsState = [...this.state.steps];
        const newStepIndex = findIndex(newStepsState, ['name', newStep]);

        newStepsState[newStepIndex] = {
            ...newStepsState[newStepIndex],
            complete: false,
        };

        if (newStepIndex !== -1) {
            this.setState({
                currentStepIndex: newStepIndex,
                steps: newStepsState,
            });
        }
    }

    incrementCheckoutStep() {
        const { steps, currentStepIndex } = this.state;
        let newStepsState = [...steps];

        // set current step to completed
        newStepsState[currentStepIndex] = {
            ...newStepsState[currentStepIndex],
            complete: true,
        };

        // set next step to not completed
        const newStepIndex = currentStepIndex + 1;
        newStepsState[newStepIndex] = {
            ...newStepsState[newStepIndex],
            complete: false,
        };

        // update steps and current state
        this.setState({
            currentStepIndex: newStepIndex,
            steps: newStepsState,
        });
    }

    getStepIndexByName(stepName: Step) {
        return findIndex(this.state.steps, ['name', stepName]);
    }

    shouldDisplayStep(stepName: Step) {
        return this.getStepIndexByName(stepName) === -1 ? false : true;
    }

    isStepComplete(stepName: Step) {
        const { steps } = this.state;

        const index = this.getStepIndexByName(stepName);

        return index === -1 ? false : steps[index]['complete'];
    }

    componentDidMount() {
        if (this.props.shopifyUrl) {
            this.getCartAndInitiatePurchase();
        }

        if (this.props.entitled) {
            return;
        }

        // TODO (future optimization): don't need to call countries or shipping APIs unless cart has physical goods.
        this.getCountriesData();
        this.getShippingData();

        if (this.props.stripeId) {
            // We have the stripeId from the id token
            // so we can perform payment setup
            this.stripeInit();
        }

        // If logged In, increment current step
        if (this.props.loggedIn) {
            this.incrementCheckoutStep();
        }
    }

    componentDidUpdate(prevProps: Readonly<CheckoutPageProps>) {
        const trialPages = this.props.services?.subscription?.trialPages;
        const productIds = this.props.services?.subscription?.productIds?.web;
        const sku = this.props.search?.sku;
        const isValidProduct = !!find(productIds, (p) => p === sku);

        if (
            !!sku &&
            !!trialPages &&
            !isValidProduct &&
            sku !== trialPages['default'] &&
            FREE_TRIAL_SKU_REGEX.test(sku)
        ) {
            this.props.search.sku = trialPages['default'];

            //update url with the default free trial product
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('sku', trialPages['default']);
            window.location.search = searchParams.toString();
        }

        if (!prevProps.shopifyUrl && this.props.shopifyUrl) {
            if (!this.state.orderContext.cart) {
                this.getCartAndInitiatePurchase();
            }
        }

        if (prevProps.location.search !== this.props.location.search) {
            const paramsPrev = new URLSearchParams(prevProps.location.search);
            const params = new URLSearchParams(this.props.location.search);
            if (paramsPrev.get('sku') !== params.get('sku')) {
                document.dispatchEvent(new CustomEvent('updatingCartItems'));
                this.getCartAndInitiatePurchase(false).then(() =>
                    document.dispatchEvent(new CustomEvent('updatingCartItems'))
                );
            }
        }

        if (!prevProps.stripeId && this.props.stripeId) {
            if (!this.state.stripeLoaded) {
                // If we haven't previously performed payment setup
                // and refreshing tokens provided a stripeId
                // then we're ready to perform the payment setup
                this.stripeInit();
            }
        }

        if (this.props.loggedIn !== prevProps.loggedIn) {
            if (this.props.loggedIn) {
                this.incrementCheckoutStep();
            }
        }
    }

    render() {
        const { entitled, location } = this.props;
        const params = new URLSearchParams(window.location.search);
        const featuresExist = params.get('features')?.toString();

        const {
            shopifyDataContext: { shippingData, countriesData },
            orderContext: { cart },
            stripeClientSecret,
            stripeDefaultCard,
            purchaseError,
            steps,
            currentStepIndex,
        } = this.state;

        const isReady = entitled || purchaseError || (cart && countriesData && shippingData);

        const pageContext = {
            theme: {
                layout: 'plain',
            },
        };

        if (this.state.internalServerError) {
            return <ErrorPage code="500" />;
        }

        const currentStepName = isEmpty(steps) ? '' : steps[currentStepIndex]['name'];

        return isReady ? (
            <Layout location={location} pageContext={pageContext}>
                {entitled ? (
                    <EntitledUser hasGear={this.hasGear} />
                ) : purchaseError ? (
                    <PurchaseError message={purchaseError} />
                ) : (
                    <ShopifyDataContext.Provider value={this.state.shopifyDataContext}>
                        <OrderContext.Provider value={this.state.orderContext}>
                            <div className={Styles.checkoutPageWrapper}>
                                <CheckoutHeaderModal />
                                {!!this.state.product && (
                                    <CheckoutFeatures product={this.state.product} />
                                )}
                                <Grid reversed="computer tablet" container>
                                    <Grid.Row>
                                        <Grid.Column
                                            mobile={16}
                                            tablet={7}
                                            computer={7}
                                            floated="right"
                                        >
                                            <CheckoutSummary
                                                version={this.getUrlParam('v')}
                                                code={this.getUrlParam('promo')}
                                                isFreeTrial={this.state.isTrial}
                                            />
                                        </Grid.Column>
                                        <Grid.Column
                                            mobile={16}
                                            tablet={8}
                                            computer={8}
                                            floated="left"
                                        >
                                            <ol
                                                className={FormStyles.formSteps}
                                                data-features={featuresExist ? 'true' : undefined}
                                            >
                                                <CheckoutAccountForm
                                                    active={currentStepName === Step.account}
                                                    complete={this.isStepComplete(Step.account)}
                                                />
                                                {this.shouldDisplayStep(Step.address) && (
                                                    <ShippingAddressForm
                                                        active={currentStepName === Step.address}
                                                        complete={this.isStepComplete(Step.address)}
                                                        incrementCheckoutStep={
                                                            this.incrementCheckoutStep
                                                        }
                                                        updateCheckoutStep={this.updateCheckoutStep}
                                                    />
                                                )}
                                                {this.shouldDisplayStep(Step.shipping) && (
                                                    <ShippingMethodForm
                                                        active={currentStepName === Step.shipping}
                                                        complete={this.isStepComplete(
                                                            Step.shipping
                                                        )}
                                                        incrementCheckoutStep={
                                                            this.incrementCheckoutStep
                                                        }
                                                        updateCheckoutStep={this.updateCheckoutStep}
                                                    />
                                                )}
                                                <CheckoutPaymentForm
                                                    active={currentStepName === Step.payment}
                                                    stripeDefaultCard={stripeDefaultCard}
                                                    stripeClientSecret={stripeClientSecret}
                                                />
                                            </ol>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <CheckoutFooter />
                            </div>
                        </OrderContext.Provider>
                    </ShopifyDataContext.Provider>
                )}
            </Layout>
        ) : (
            <div className={Styles.loaderWrapper}>
                <Dimmer active inverted>
                    <Loader>Preparing Checkout</Loader>
                </Dimmer>
            </div>
        );
    }
}

export default connector(withLocation(CheckoutPage));
