import React, { useContext, useEffect, useState } from 'react';
import { format, addDays } from 'date-fns';
import { Item } from 'semantic-ui-react';
import { first } from 'lodash';
import { OrderContext } from 'src/components/checkout/checkout-context';
import { shopifyImageResize } from 'src/utils/miscellanous';
import { getProductDetails } from 'src/utils/sdk-utils';
import * as Styles from 'src/styles/checkout.module.scss';
import { ShopifyItem } from './CheckoutPage';
import withLocation from '../withLocation';
import { notifyBugsnagError } from 'src/utils/bugsnag';

interface Discount {
    is_permanent: boolean;
    max_intervals: number;
}

interface CheckoutItemProps {
    item: ShopifyItem;
    discount?: Discount;
    location: any;
    discountAmount?: number;
}

const CheckoutItem: React.FC<CheckoutItemProps> = ({
    item,
    discountAmount,
    discount,
    location,
}) => {
    const {
        product_title,
        image,
        quantity,
        variant_title,
        final_price,
        requires_shipping,
        sku,
        handle,
    } = item;

    const [disabled, setDisabled] = useState(false);
    const [metafieldData, setmetafieldData] = useState<string>('');
    const { is_permanent, max_intervals = 0 } = discount || {};
    const { freeTrialDays } = useContext(OrderContext);
    const trialEndDate = format(addDays(new Date(), freeTrialDays), 'MM/dd/yy');
    const formattedPrice = `$${(final_price / 100).toFixed(2)}`;
    const smallImage = shopifyImageResize(image, 240);
    const isDigitalPresent = handle === 'streaming-access';
    const toggleAvailability = () => setDisabled((p) => !p);

    useEffect(() => {
        const load = async () => {
            try {
                if (!!sku) {
                    const response = await getProductDetails(sku);
                    const product = first(response);

                    if (product) {
                        setmetafieldData(product.custom_data?.alt_title);
                    }
                }
            } catch (err) {
                notifyBugsnagError(error);

                console.log({ err });
                return {};
            }
        };

        load();
    }, [sku]);

    useEffect(() => {
        document.addEventListener('updatingCartItems', toggleAvailability);
        return () => {
            document.removeEventListener('updatingCartItems', toggleAvailability);
        };
    }, [toggleAvailability]);

    const params = new URLSearchParams(location.search);
    const featuresExist = params.get('features')?.toString() === 'true';

    return (
        <div
            className={`${Styles.previewWrapper} ${disabled ? Styles.previewWrapperDisabled : ''}`}
        >
            <div className={Styles.previewContent}>
                <Item>
                    <div className={Styles.contentWrapper}>
                        {!featuresExist && (
                            <div className={Styles.itemImageWrapper}>
                                <Item.Image src={smallImage} alt={product_title} size="small" />
                                <div className={Styles.quantityCircle}>{quantity}</div>
                            </div>
                        )}
                        <Item.Content verticalAlign="middle">
                            <Item.Header>{product_title}</Item.Header>
                            {requires_shipping ? (
                                <>
                                    <Item.Meta>
                                        {metafieldData ? metafieldData : variant_title}
                                    </Item.Meta>
                                    {discountAmount ? (
                                        <Item.Meta>
                                            <span className={Styles.strikethrough}>
                                                {formattedPrice}
                                            </span>{' '}
                                            <span className="color-pv-black">{`$${(
                                                (final_price - discountAmount) /
                                                100
                                            ).toFixed(2)}`}</span>
                                        </Item.Meta>
                                    ) : (
                                        <Item.Meta>{formattedPrice}</Item.Meta>
                                    )}
                                </>
                            ) : (
                                <>
                                    {variant_title &&
                                        (discountAmount ? (
                                            <>
                                                {is_permanent && (
                                                    <Item.Meta>
                                                        <span className={Styles.strikethrough}>
                                                            {formattedPrice}
                                                        </span>{' '}
                                                        <span className="color-pv-black">{`$${(
                                                            (final_price - discountAmount) /
                                                            100
                                                        ).toFixed(2)}`}</span>{' '}
                                                        <span>/ {variant_title.toLowerCase()}</span>
                                                    </Item.Meta>
                                                )}
                                                {!is_permanent && max_intervals <= 1 && (
                                                    <>
                                                        <Item.Meta>
                                                            <span className="color-pv-black">
                                                                {`$${(
                                                                    (final_price - discountAmount) /
                                                                    100
                                                                ).toFixed(2)}`}{' '}
                                                                / first{' '}
                                                                {variant_title.toLowerCase() ===
                                                                '1 month'
                                                                    ? 'month'
                                                                    : variant_title.toLowerCase()}
                                                            </span>
                                                        </Item.Meta>
                                                        <Item.Meta>
                                                            {formattedPrice} /{' '}
                                                            {variant_title.toLowerCase() ===
                                                            '1 month'
                                                                ? 'month'
                                                                : `every ${variant_title.toLowerCase()}`}{' '}
                                                            after that
                                                        </Item.Meta>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <Item.Meta>
                                                {isDigitalPresent ? (
                                                    <div>
                                                        <span>{formattedPrice}</span>
                                                        <span>{metafieldData}</span>
                                                    </div>
                                                ) : (
                                                    `${formattedPrice} / ${variant_title}`
                                                )}
                                            </Item.Meta>
                                        ))}
                                    {!!freeTrialDays && (
                                        <Item.Meta>free trial ends {trialEndDate}</Item.Meta>
                                    )}
                                </>
                            )}
                        </Item.Content>
                    </div>
                </Item>
            </div>
        </div>
    );
};

export default withLocation(CheckoutItem);
