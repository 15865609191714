import React, { forwardRef, useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import classNames from 'classnames';

import * as Styles from 'src/styles/checkout.module.scss';

//TODO: fill out these anys
interface ShippingMethodProps {
    label: string;
    price: string;
    rateId: number;
    checked: boolean;
    loading: boolean;
    onChange: (e: any) => void;
    onBlur: (e: any) => void;
    setLoading: (state: boolean) => void;
}
const ShippingMethod = forwardRef(
    (
        {
            label,
            price,
            rateId,
            onChange,
            onBlur,
            checked,
            loading,
            setLoading,
        }: ShippingMethodProps,
        ref
    ) => {
        const [checkedClassActive, setCheckedClassActive] = useState(false);

        const handleClick = (e) => {
            setLoading(true);
            setCheckedClassActive(true);
        };

        const inputClassnames = classNames({
            checked: checkedClassActive,
        });

        useEffect(() => {
            setLoading(false);
        }, [checked]);

        const displayPrice = price
            ? price === '0'
                ? 'free'
                : `$${price}`
            : 'enter address to calculate';

        return (
            <div className={classNames(Styles.shippingMethod, { loading })} ref={ref}>
                <Form.Field
                    onClick={handleClick}
                    className={inputClassnames}
                    label={label}
                    control="input"
                    type="radio"
                    name="rate"
                    checked={checked}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={rateId}
                />
                <p>{displayPrice}</p>
            </div>
        );
    }
);

export default ShippingMethod;
