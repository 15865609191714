import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import Pvolve from '@pvolve/sdk';
import classNames from 'classnames';
import Cookies from 'js-cookie';

import { Link } from 'gatsby';
import { connect, ConnectedProps } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { isEmpty, omit } from 'lodash';
import { Button, Divider, Form } from 'semantic-ui-react';
import { WindowLocation } from '@reach/router';
import { ParsedQuery } from 'query-string';
import { IState } from '@pvolve/sdk/src/redux/selectors';

import Actions from 'src/state/root-actions';
import Selectors from 'src/state/root-selectors';
import SocialButton from 'src/components/auth/SocialButton';
import withLocation from 'src/components/withLocation';

import { OrderContext } from 'src/components/checkout/checkout-context';
import {
    CheckoutEmailAuthEventPayload,
    CheckoutEmailLookupEventPayload,
    CheckoutSocialCompleteEventPayload,
    CheckoutSocialTriggeredEventPayload,
} from 'src/state/web/sagas';
import { Banner, CircleCheckmark, Icon } from 'src/components/shared';
import {
    FORM_MESSAGES,
    getErrorMessage,
    getInfoMessage,
    validateEmail,
    validatePassword,
    validatePasswordUpper,
    validatePasswordNumber,
    validateInputName,
} from 'src/utils/form-utils';
import { notifyBugsnagError } from 'src/utils/bugsnag';

import * as Styles from 'src/styles/checkout.module.scss';
import classnames from 'classnames';
import { InfoMessageTypes } from '../shared/InfoMessage';
import { IdentifyFields, MarketingOptedInEventPayload } from 'src/state/analytics/segment/types';

interface FormValues {
    general?: string;
    email?: string;
    password?: string;
    firstName?: string;
    lastName?: string;
    optIn?: boolean;
}

type field = keyof FormValues;

type FormErrors = {
    [key in field]?: string;
};

const connector = connect(
    (state: IState) => ({
        loggedIn: Selectors.auth.loggedIn(state),
        userAttributes: Selectors.account.userAttributes(state) || {},
        entitled: Selectors.auth.entitled(state),
    }),
    {
        login: (props) => Actions.auth.login.trigger(props),
        signup: (props) => Actions.auth.signup.trigger(props),
        getAllAttrs: () => Actions.account.getAllAttrs.trigger(),
        checkoutEmailAuthComplete: (data: CheckoutEmailAuthEventPayload) =>
            Actions.web.checkoutEmailAuthComplete(data),
        checkoutEmailAuthLookup: (data: CheckoutEmailLookupEventPayload) =>
            Actions.web.checkoutEmailAuthLookup(data),
        checkoutSocialAuthComplete: (data: CheckoutSocialCompleteEventPayload) =>
            Actions.web.checkoutSocialAuthComplete(data),
        checkoutSocialAuthTriggered: (data: CheckoutSocialTriggeredEventPayload) =>
            Actions.web.checkoutSocialAuthTriggered(data),
        trackCheckoutStepViewed: (isFreeTrial: boolean) => {
            return isFreeTrial
                ? Actions.segment.track.trialSignupStepViewed()
                : Actions.segment.track.checkoutStepViewed();
        },
        trackCheckoutStepCompleted: (isFreeTrial: boolean, data: object = {}) => {
            return isFreeTrial
                ? Actions.segment.track.trialSignupStepCompleted(data)
                : Actions.segment.track.checkoutStepCompleted(data);
        },
        identifyUser: (data: IdentifyFields) => Actions.segment.identify(data),
        trackEmailMarketingOptedIn: (data: MarketingOptedInEventPayload) =>
            Actions.segment.track.marketingOptedIn(data),
    }
);

interface CheckoutAccountFormProps extends ConnectedProps<typeof connector> {
    active: boolean;
    complete: boolean;
    location: WindowLocation;
    search: ParsedQuery;
}

const CheckoutAccountForm = ({
    loggedIn,
    userAttributes,
    entitled,
    login,
    signup,
    getAllAttrs,
    active,
    complete,
    search,
    checkoutEmailAuthComplete,
    checkoutEmailAuthLookup,
    checkoutSocialAuthTriggered,
    checkoutSocialAuthComplete,
    identifyUser,
    trackCheckoutStepCompleted,
    trackCheckoutStepViewed,
    trackEmailMarketingOptedIn,
}: CheckoutAccountFormProps) => {
    const [isLogin, setIsLogin] = useState(false);
    const [isSignup, setIsSignup] = useState(false);
    const [user, setUser] = useState<any | null>(null);
    const [showPassword, setShowPassword] = useState(false);

    const { draft_order, freeTrialDays } = useContext(OrderContext);

    const sectionRef = useRef<HTMLHeadingElement>(null);
    const scrollToElm = () => {
        sectionRef?.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        /* after user signsup/logins, get user attributes */
        if (loggedIn) {
            getAllAttrs();
        }
    }, [loggedIn]);

    useEffect(() => {
        /* after user signsup/logins, get user attributes */
        if (userAttributes?.object) {
            const getUser = async (email?: string) => {
                if (email) {
                    try {
                        const userResponse = await Pvolve.api.account.findUserByEmail(email);
                        setUser(userResponse);

                        // GTM for social auth complete event
                        if (search && !isEmpty(search.code)) {
                            checkoutSocialAuthComplete({
                                provider: userResponse?.provider,
                            });
                            // START Segment Track event for Social login
                            trackCheckoutStepCompleted(!!freeTrialDays, {
                                account_type: userResponse?.provider,
                            });
                            if (!entitled) {
                                trackCheckoutStepViewed(!!freeTrialDays);
                            }
                            // END Segment Track events
                        }
                    } catch (error) {
                        notifyBugsnagError(error);
                        console.log(error);
                    }
                }
            };

            getUser(userAttributes.object.email);
        }

        if (!loggedIn && search.email) {
            setUserEmail();
        }
    }, [userAttributes, search]);

    const toggleShowPassword = () => setShowPassword((showPasswordState) => !showPasswordState);

    const resetState = () => {
        setUser(null);
        setIsSignup(false);
        setIsLogin(false);
    };

    const setUserEmail = () => {
        const GUEST_USER_EMAIL_COOKIE_NAME = 'pv-guest';
        const guestEmail = Cookies.get(GUEST_USER_EMAIL_COOKIE_NAME);

        initialValues.email = guestEmail;
        scrollToElm();
        Cookies.remove(GUEST_USER_EMAIL_COOKIE_NAME, { path: '' });
    };

    const onFormSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
        if (!isLogin && !isSignup) {
            // clear previous states
            actions.setFieldError('general', '');
            resetState();

            // do email find user and/or signup/login calls
            const findUser = async () => {
                try {
                    const user = await Pvolve.api.account.findUserByEmail(values.email);
                    setUser(user);

                    if (user?.status === 'CONFIRMED') {
                        // setIsLogin to display login form
                        setIsLogin(true);

                        // GTM email lookup success
                        checkoutEmailAuthLookup({
                            userFound: true,
                        });
                    } else if (user?.status === 'EXTERNAL_PROVIDER') {
                        actions.setFieldError(
                            'general',
                            `You previously registered with ${user?.provider}. Please use ${user?.provider} to login.`
                        );

                        // Segment/GTM email lookup with error
                        checkoutEmailAuthLookup({
                            userFound: true,
                            error: 'EXTERNAL_PROVIDER',
                        });
                    }

                    actions.setSubmitting(false);
                } catch (error) {
                    notifyBugsnagError(error);

                    if (error?.response?.status === 404) {
                        // setIsSignup to display signup form
                        setIsSignup(true);

                        // Segment/GTM email lookup not found
                        checkoutEmailAuthLookup({
                            userFound: false,
                        });
                    } else {
                        const errMsg =
                            error?.message || 'Oops! Something went wrong. Please try again.';
                        actions.setFieldError('general', errMsg);

                        // Segment/GTM email lookup not found
                        checkoutEmailAuthLookup({
                            userFound: false,
                            error: 'GENERAL',
                        });
                    }

                    actions.setSubmitting(false);
                    actions.setFieldTouched('password', false);
                    actions.setFieldTouched('firstName', false);
                    actions.setFieldTouched('lastName', false);
                }
                // START Segment Identify and Track events for Finding user
                if (values.email) {
                    identifyUser({
                        userId: values.email,
                        traits: {
                            email: values.email,
                        },
                    });
                }
                trackCheckoutStepCompleted(!!freeTrialDays);
                trackCheckoutStepViewed(!!freeTrialDays);
                // END Segment Identify and Track events
            };

            findUser();
        } else {
            const onFulfill = () => actions.setSubmitting(false);

            if (isLogin) {
                const onSuccess = () => {
                    // GTM for email login success
                    checkoutEmailAuthComplete({
                        isSignup: false,
                    });
                    // START Segment Track events
                    trackCheckoutStepCompleted(!!freeTrialDays, {
                        account_type: 'Pvolve',
                    });
                    trackCheckoutStepViewed(!!freeTrialDays);
                    // END Segment Track events
                };

                const onFailure = (message: string) => {
                    actions.setFieldError('general', message);

                    // GTM for email login failure
                    checkoutEmailAuthComplete({
                        isSignup: false,
                        error: message,
                    });
                };

                const loginAuthProps = omit(values, ['general', 'optIn']);

                login({
                    ...loginAuthProps,
                    onFailure,
                    onFulfill,
                    onSuccess,
                });
            } else if (isSignup) {
                const onSuccess = () => {
                    // GTM for email signup success
                    checkoutEmailAuthComplete({
                        isSignup: true,
                    });
                    // START Segment Identify and Track for Signup Step and Marketing Opted In
                    if (values.optIn && values.email) {
                        identifyUser({
                            userId: values.email,
                            traits: {
                                email_marketing_opted_in: 'opted_in',
                                email: values.email,
                            },
                        });
                        trackEmailMarketingOptedIn({
                            email: values.email,
                            source: 'account creation',
                            source_detail: 'checkout',
                            is_email_opt_in: true,
                        });
                    }
                    trackCheckoutStepCompleted(!!freeTrialDays, {
                        account_type: 'Pvolve',
                    });
                    if (!entitled) {
                        trackCheckoutStepViewed(!!freeTrialDays);
                    }
                    // END Segment Identify and Track
                };

                const onFailure = (message: string) => {
                    actions.setFieldError('general', message);

                    // Segment/GTM for email signup failure
                    checkoutEmailAuthComplete({
                        isSignup: true,
                        error: message,
                    });
                };

                const signupAuthProps = omit(values, ['general']);

                signup({
                    ...signupAuthProps,
                    onFailure,
                    onFulfill,
                    onSuccess,
                });
            }
        }
    };

    const validate = (values: FormValues) => {
        const errors: FormErrors = {};

        const emailError = validateEmail(values);
        const passwordError = validatePassword(values);
        const passwordUpperError = validatePasswordUpper(values);
        const passwordNumberError = validatePasswordNumber(values);
        const firstNameError = validateInputName(values.firstName);
        const lastNameError = validateInputName(values.lastName);

        if (!(isSignup || isLogin) && emailError.email) {
            errors.email = emailError.email;
        }

        if (isSignup && !isLogin) {
            if (passwordError.password) {
                errors.password = passwordError.password;
            }

            if (passwordUpperError.password) {
                errors.password = passwordUpperError.password;
            }

            if (passwordNumberError.password) {
                errors.password = passwordNumberError.password;
            }

            if (firstNameError) {
                errors.firstName = firstNameError;
            }

            if (lastNameError) {
                errors.lastName = lastNameError;
            }
        }

        return errors;
    };

    const passwordError = (values: FormValues, validate, message: string) => {
        let type: InfoMessageTypes = 'INFO_ERROR';

        if (values?.password?.length) {
            type = validate(values).password ? 'INFO_ERROR' : 'SUCCESS';
        }

        return getInfoMessage(message, type);
    };

    /*
    const handleLogout = () => {
        updateOrderContext({
            draftOrderUpdating: true,
        });

        if (!isEmpty(errorMsg)) {
            setErrorMsg(null);
        }

        const onSuccess = async () => {
            updateCheckoutStep(Step.account);

            // If a user logs out, clear out any entered address, shipping method, and payment info
            try {
                const { data: updatedDraftOrder } = await Pvolve.api.commerce.updateV2(
                    {
                        draft_order_id: draft_order?.draft_order_id,
                        shipping_rate_id: -1,
                        shipping_address: {},
                        billing_address: {},
                    },
                    false
                );

                updateOrderContext({
                    draft_order: updatedDraftOrder,
                    shippingAddress: [],
                    shippingRateId: -1,
                    draftOrderUpdating: false,
                });

                resetState();
            } catch (error) {
                setErrorMsg(GENERAL_ERROR_MSG);
                updateOrderContext({
                    draftOrderUpdating: false,
                });
            }
        };

        const onFailure = () => {
            setErrorMsg(GENERAL_ERROR_MSG);

            updateOrderContext({
                draftOrderUpdating: false,
            });
        };

        logout({
            onSuccess,
            onFailure,
        });
    };
    */

    const initialValues: FormValues = {
        general: '',
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        optIn: true,
    };

    const loggedInAs = useCallback(() => {
        const name = [userAttributes?.object?.first_name, userAttributes?.object?.last_name]
            .join(' ')
            .trim();
        return !isEmpty(name) ? name : userAttributes?.object?.email;
    }, [userAttributes]);

    if (complete && !userAttributes && !user) {
        return null;
    }

    return (
        <li className={classNames(Styles.accountForm, { complete })}>
            {active ? (
                <Formik initialValues={initialValues} validate={validate} onSubmit={onFormSubmit}>
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        errors,
                        touched,
                        isSubmitting,
                    }) => {
                        // this is why the form randomly disables
                        const disableEmailField =
                            loggedIn || (!isEmpty(values.email) && (!!user || isSignup));
                        const emailFieldIcon = disableEmailField ? (
                            <Icon name="pv-pencil" color="gray-50" onClick={resetState} />
                        ) : null;
                        const emailFieldError =
                            errors.email && touched.email
                                ? getInfoMessage(errors.email, 'INFO_ERROR')
                                : false;

                        let state = {
                            ...search,
                        };

                        if (draft_order?.draft_order_id) {
                            state.draft = draft_order?.draft_order_id;
                        }

                        const socialCallback = (provider: string) => {
                            checkoutSocialAuthTriggered({
                                provider,
                            });
                            // START SEGMENT Track event for Social Login
                            // @param socialAuthTriggered - Flag to save on sessionStorage the step count as backup
                            trackCheckoutStepCompleted(!!freeTrialDays, {
                                socialAuthTriggered: true,
                            });
                            // END SEGMENT Track event
                        };

                        return (
                            <Form id="account-form" onSubmit={handleSubmit} noValidate>
                                <h4 ref={sectionRef}>Create Account / Login</h4>
                                <p className="p1">
                                    These are the credentials you use to log into your digital
                                    membership.
                                </p>
                                <div className={Styles.socialButtons}>
                                    <SocialButton
                                        redirectPath="/checkout/"
                                        provider="apple"
                                        state={state}
                                        onClick={socialCallback}
                                    />
                                    <SocialButton
                                        redirectPath="/checkout/"
                                        provider="google"
                                        state={state}
                                        onClick={socialCallback}
                                    />
                                    <SocialButton
                                        redirectPath="/checkout/"
                                        provider="facebook"
                                        state={state}
                                        onClick={socialCallback}
                                    />
                                </div>
                                <Divider horizontal className={Styles.divider}>
                                    Or
                                </Divider>
                                <Form.Input
                                    id="checkout-email"
                                    className={Styles.email}
                                    name="email"
                                    type="email"
                                    label={{
                                        children: 'email',
                                        htmlFor: 'checkout-email',
                                        className: values.email ? '' : 'hidden',
                                    }}
                                    icon={emailFieldIcon}
                                    placeholder="Email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    error={emailFieldError}
                                    disabled={disableEmailField}
                                />
                                {(isLogin || isSignup) && (
                                    <>
                                        <p className={classnames(Styles.passwordCopy, 'p1')}>
                                            {isSignup
                                                ? `Looks like you're new here! Please create your password.`
                                                : user?.given_name &&
                                                  user?.given_name !== 'First_Name'
                                                    ? `Welcome back, ${user.given_name}!`
                                                    : 'Welcome back!'}
                                        </p>
                                        <Form.Field>
                                            <Form.Input
                                                className={Styles.passwordIconButton}
                                                icon={
                                                    <Icon
                                                        name={`pv-eyeball${
                                                            showPassword ? '-off' : ''
                                                        }`}
                                                        onClick={toggleShowPassword}
                                                    />
                                                }
                                                label={{
                                                    children: isSignup
                                                        ? 'Create Password'
                                                        : 'Password',
                                                    htmlFor: 'checkout-password',
                                                    className: values.password ? '' : 'hidden',
                                                }}
                                                id="checkout-password"
                                                name="password"
                                                type={showPassword ? 'text' : 'password'}
                                                placeholder={
                                                    isSignup ? 'Create Password' : 'Password'
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.password}
                                                error={errors.password && touched.password}
                                            />
                                            {passwordError(
                                                values,
                                                validatePassword,
                                                FORM_MESSAGES.passwordValidation
                                            )}
                                            {passwordError(
                                                values,
                                                validatePasswordUpper,
                                                FORM_MESSAGES.upperCaseLetter
                                            )}
                                            {passwordError(
                                                values,
                                                validatePasswordNumber,
                                                FORM_MESSAGES.containNumber
                                            )}
                                        </Form.Field>
                                        {!isLogin && isSignup && (
                                            <div className={Styles.namesFieldsContainer}>
                                                <Form.Field className={Styles.nameField}>
                                                    <Form.Input
                                                        label={{
                                                            children: 'First Name',
                                                            htmlFor: 'signup-first-name',
                                                            className: values.firstName
                                                                ? ''
                                                                : 'hidden',
                                                        }}
                                                        id="checkout-first-name"
                                                        name="firstName"
                                                        type="text"
                                                        placeholder="First name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.firstName}
                                                        error={
                                                            errors.firstName &&
                                                            touched.firstName &&
                                                            getErrorMessage(errors.firstName)
                                                        }
                                                    />
                                                </Form.Field>
                                                <Form.Field className={Styles.nameField}>
                                                    <Form.Input
                                                        label={{
                                                            children: 'Last Name',
                                                            htmlFor: 'signup-last-name',
                                                            className: values.lastName
                                                                ? ''
                                                                : 'hidden',
                                                        }}
                                                        id="checkout-last-name"
                                                        name="lastName"
                                                        type="text"
                                                        placeholder="Last name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.lastName}
                                                        error={
                                                            errors.lastName &&
                                                            touched.lastName &&
                                                            getErrorMessage(errors.lastName)
                                                        }
                                                    />
                                                </Form.Field>
                                            </div>
                                        )}

                                        {isSignup && (
                                            <Form.Checkbox
                                                id="optIn"
                                                name="optIn"
                                                label="Keep me up to date with P.volve news and exclusive offers"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.optIn}
                                            />
                                        )}
                                    </>
                                )}
                                {!isEmpty(errors.general) && (
                                    <Banner type="ERROR">{errors.general}</Banner>
                                )}
                                <div className={Styles.formActionsWrapper}>
                                    {isLogin ? (
                                        <Link
                                            className={classNames('bold', { hidden: !isLogin })}
                                            to="/loginHelp"
                                            state={{ email: values?.email }}
                                        >
                                            I forgot my password ¯\_(ツ)_/¯
                                        </Link>
                                    ) : (
                                        <div />
                                    )}
                                    <Button type="submit" size="big" loading={isSubmitting} primary>
                                        Next
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            ) : (
                complete && (
                    <>
                        <h4>
                            <CircleCheckmark size={40} />
                            Logged In As
                        </h4>
                        {userAttributes && user && <p>{loggedInAs()}</p>}
                    </>
                )
            )}
        </li>
    );
};

export default connector(withLocation(CheckoutAccountForm));
