// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FC, useCallback, useEffect, useMemo, useState, memo } from 'react';
import * as PlanTypeStyles from 'src/styles/checkout-plan-types.module.scss';
import * as Styles from 'src/styles/checkout.module.scss';
import withLocation from 'src/components/withLocation';
import { navigate } from '@reach/router';
import Pvolve from '@pvolve/sdk';
import { Product } from '@pvolve/sdk/src/SubscriptionService';
import { useDispatch } from 'react-redux';
import Actions from 'src/state/root-actions';
import cn from 'classnames';
import { notifyBugsnagError } from 'src/utils/bugsnag';

const SUBSCRIPTION_PRODUCTS = ['12month_trial14', '1month_trial14'];

interface PlanTypesProps {
    location: any;
}

interface UpdatePlanTypeProps {
    sku: string;
    productTitle: string;
    data_cta_name: string;
    event: React.MouseEvent<HTMLDivElement, MouseEvent>;
}

const getTrialEndDate = (trialDuration: number) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + trialDuration);
    return expirationDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
};

const CheckoutPlanTypes: FC<PlanTypesProps> = ({ location }) => {
    const dispatch = useDispatch();
    const params = useMemo(() => new URLSearchParams(decodeURIComponent(location.search || '')), [
        location.search,
    ]);
    const selectedSubscription = params.get('sku')?.toString();
    const [subscriptionProducts, setSubscriptionProducts] = useState<Product[]>([]);

    useEffect(() => {
        Pvolve.api.subscription
            .listSubscriptionProducts(SUBSCRIPTION_PRODUCTS)
            .then((res) => setSubscriptionProducts(res))
            .catch((error) => {
                notifyBugsnagError(error);
                console.log(error);
            });
    }, [SUBSCRIPTION_PRODUCTS]);

    const updatePlanType = useCallback(
        (data: UpdatePlanTypeProps) => {
            (data.event?.target as HTMLAnchorElement).innerText = data.productTitle;
            dispatch(
                Actions.segment.track.ctaClicked({
                    cta_name: data?.data_cta_name,
                    event: data?.event,
                })
            );
            params.set('sku', data.sku);
            navigate(`${location.pathname}?${params.toString()}`);
        },
        [params, location.pathname, dispatch]
    );

    const Plans: FC<Product> = (item: Product) => {
        const classes = ['plan-types__item'];
        const price = item.cost.currency_amount;
        const chargeInterval = item.subscription_details.interval;
        const data_cta_name = chargeInterval >= 12 ? 'trialAnnualRenewal' : 'trialMonthlyRenewal';
        const chargeFrequency = item.subscription_details.interval_type;
        const productTitle = item.custom_data?.alternative_title || item.title;
        let charge = item.cost.currency_amount / chargeInterval;
        let compareAtPriceCharge;
        if (item.custom_data?.discount && !!+item.custom_data?.discount) {
            const compareAtPriceTotal = price * (100 / +item.custom_data.discount);
            compareAtPriceCharge = compareAtPriceTotal / chargeInterval;
        }
        if (selectedSubscription === item.sku) {
            classes.push(PlanTypeStyles.itemActive);
        }
        return (
            <div
                className={classes.join(' ')}
                tabIndex={0}
                onClick={(event: any) =>
                    updatePlanType({
                        sku: item.sku,
                        productTitle,
                        data_cta_name,
                        event,
                    })
                }
            >
                <div className={PlanTypeStyles.radio} />
                <div className={PlanTypeStyles.itemContent}>
                    <span className={PlanTypeStyles.itemTitle}>{productTitle}</span>
                    <div className={PlanTypeStyles.price}>
                        <span
                            className={`plan-types__label ${
                                compareAtPriceCharge ? 'plan-types__label--cross' : ''
                            }`}
                        >
                            $
                            {compareAtPriceCharge?.toFixed(2) ||
                                (Math.floor(charge * 100) / 100).toFixed(2)}{' '}
                            a {chargeFrequency}
                        </span>
                        {!!compareAtPriceCharge && (
                            <span
                                className={`${PlanTypeStyles.label} ${PlanTypeStyles.labelSuccess}`}
                            >
                                ${(Math.floor(charge * 100) / 100).toFixed(2)} a {chargeFrequency}
                            </span>
                        )}
                        {chargeInterval > 1 && (
                            <span className={`${PlanTypeStyles.label} ${PlanTypeStyles.labelSub}`}>
                                Billed ${price?.toFixed(2)} every {chargeInterval}{' '}
                                {chargeFrequency + (chargeInterval > 1 ? 's' : '')}
                            </span>
                        )}
                    </div>
                </div>
                {compareAtPriceCharge && <span className={PlanTypeStyles.discount}>SALE</span>}
            </div>
        );
    };

    if (params.get('types')?.toString() !== 'true') {
        return null;
    }

    const activeSubscription = subscriptionProducts.find(({ sku }) => sku === selectedSubscription);

    return (
        <div className={PlanTypeStyles.container}>
            <div className={Styles.previewWrapper}>
                <div className={Styles.previewContent}>
                    <div className={PlanTypeStyles.header}>
                        <h4 className={PlanTypeStyles.title}>Membership Type</h4>
                    </div>
                    <div>
                        {subscriptionProducts.map((item) => (
                            <Plans {...item} key={item.shopify_variant_id} />
                        ))}
                    </div>
                    <div className={PlanTypeStyles.footer}>
                        <div className={PlanTypeStyles.group}>
                            <h2 className={PlanTypeStyles.title}>Total due today</h2>
                            <span className={PlanTypeStyles.title}>$0</span>
                        </div>
                        {activeSubscription && (
                            <span
                                className={cn(
                                    PlanTypeStyles.label,
                                    PlanTypeStyles.labelSub,
                                    PlanTypeStyles.labelGutter
                                )}
                            >
                                ${activeSubscription?.cost.currency_amount} after your trial ends on{' '}
                                {getTrialEndDate(14)}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(withLocation(CheckoutPlanTypes));
